import { ORDER_STORAGE_KEY, PUSH_ORDER } from '../contexts/constains';
import storage from '../utils/storage';

export const orderReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case PUSH_ORDER:
            let orderList = [...state.orders, payload];
            storage.set(ORDER_STORAGE_KEY, orderList);
            return {
                ...state,
                orders: orderList,
            };
        default:
            return state;
    }
};
