const PageModal = ({ active, onClose, children }) => {
    return (
        <div>
            <div className={`fixed z-30 inset-0 bg-gray-000 transition ease-out duration-300 delay-0 ${active ? '!translate-x-0' : 'translate-x-full'}`}>{children}</div>
        </div>
    );
};

const PageHeaderModal = ({ title, subTitle, onClose, children }) => {
    return (
        <div className="relative w-full shadow-default">
            <div className="container mx-auto pl-0 flex items-start justify-between">
                <div className="flex items-center py-1">
                    <div className="flex p-3 pl-5 items-center justify-center" onClick={onClose}>
                        <i className="fi-rr-angle-left text-base"></i>
                    </div>
                    <span className="flex flex-col leading-3">
                        <span className="text-lg font-bold leading-6">{title}</span>
                        <span>{subTitle}</span>
                    </span>
                </div>
                <div>{children}</div>
            </div>
        </div>
    );
};

export { PageHeaderModal };
export default PageModal;
