import { CART_MODAL_LOCATION, FOOD_MODAL_LOCATION, FOOD_MODAL_UPDATE_LOCATION, HOME_LOCATION, PAGE_CHECKOUT_LOCATION, PAGE_INFO_LOCATION } from '../contexts/constains';

const body = document.body;

// call this to Disable
function disableScroll() {
    body.style.overflowY = 'hidden';
}

// call this to Enable
function enableScroll() {
    body.style.overflowY = 'auto';
}

const handleScoll = (top, behavior = true) => {
    var rootElement = document.documentElement;
    rootElement.scrollTo({
        top,
        behavior: behavior ? 'smooth' : 'auto',
    });
};

function lockScroll(type) {
    let body = document.body,
        foodModal = document.getElementById('food-modal-scroll'),
        cartModal = document.getElementById('cart-modal-scroll'),
        infoModal = document.getElementById('form-info-customer'),
        checkoutModal = document.getElementById('form-info-customer');

    body.style.overflowY = 'hidden';
    foodModal.style.overflowY = 'hidden';
    cartModal.style.overflowY = 'hidden';
    if (infoModal) infoModal.style.overflowY = 'hidden';
    if (checkoutModal) checkoutModal.style.overflowY = 'hidden';

    switch (type) {
        case HOME_LOCATION:
            body.style.overflowY = 'auto';
            break;
        case FOOD_MODAL_LOCATION:
        case FOOD_MODAL_UPDATE_LOCATION:
            foodModal.style.overflowY = 'auto';
            break;
        case CART_MODAL_LOCATION:
            cartModal.style.overflowY = 'auto';
            break;
        case PAGE_INFO_LOCATION:
            infoModal.style.overflowY = 'auto';
            break;
        case PAGE_CHECKOUT_LOCATION:
            checkoutModal.style.overflowY = 'auto';
            break;
        default:
            document.body.style.overflowY = 'auto';
            foodModal.style.overflowY = 'auto';
            cartModal.style.overflowY = 'auto';
            if (infoModal) infoModal.style.overflowY = 'auto';
            if (checkoutModal) checkoutModal.style.overflowY = 'auto';
            break;
    }
}

export { disableScroll, enableScroll, handleScoll, lockScroll };
