import { useContext, useEffect, useRef, useState } from 'react';
import Button from '../Button';
import Card, { CardBody } from '../Card';
import PageModal, { PageHeaderModal } from '../PageModal';
import TabBar from '../TabBar';
import AsyncSelect from 'react-select/async';
import { CartContext } from '../../contexts/CartContext';
import checkValidateSave from '../../utils/validate';

import * as axiosTemplate from '../../utils/axiosTemplate';
import { API_GET_MAP4D, ENUM_METHOD_GET, ENUM_PROJECT_ID_PUBLIC } from '../../contexts/constains';
import { removeEmojis } from '../../utils';

const PageInfo = ({ active, onNext, onClose }) => {
    const {
        cartState: { infoCustomer },
    } = useContext(CartContext);

    const [infoState, setInfoState] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const isInputPreviouslyBlurred = useRef(false);
    const handleSubmit = () => {
        if (!checkValidateForm()) return;
        onNext(infoState);
    };

    const fetchDataAddress = async (inputValue) => {
    if (inputValue.trim() === '') return [];

    const project = ENUM_PROJECT_ID_PUBLIC;
        const method = ENUM_METHOD_GET;
        const publicKey = "e7d52d87ae69b17207599267190ea5e773";
        const api = `https://maps.track-asia.com/api/v1/search?lang=vi&text=${encodeURIComponent(inputValue)}&key=${publicKey}`;
    try {
        const res = await axiosTemplate.get(project, method, api, { loading: false });
        
        // Assuming Track Asia's API returns a similar response structure
        if (res.features) {
            let resAddresses = res.features.map((obj) => ({
              value: obj.properties.id,
              label: obj.properties.label, // Adjust based on Track Asia's response
              location: obj.geometry.coordinates, // Longitude, Latitude
            }));
            return resAddresses;
        }
    } catch (error) {
        console.error("Error fetching data from Track Asia API", error);
    }
    return [];
};

    const loadOptions = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(fetchDataAddress(inputValue));
            }, 500);
        });
    
    
    const checkValidateForm = () => {
        const formInfoCustomer = document.querySelector('#form-info-customer');

        const addRedBorder = (selector, text) => {
            const element = formInfoCustomer.querySelector(selector);
            element.classList.add('!border-red-brand-700');
        };

        if (!infoState.address.address) addRedBorder('.select-validate-address');

        const isFormValid = checkValidateSave('#form-info-customer') && !!infoState.address.address;

        return isFormValid;
    };

    useEffect(() => {
        setInfoState(infoCustomer);
    }, [infoCustomer, active]);

    const createOptionFromInputValue = () => {
        if (!inputValue) return;
        setInfoState({ ...infoState, address: { ...infoState.address, address: { label: inputValue, value: inputValue } } });
    };

    const onMenuClose = () => {
        if (!isInputPreviouslyBlurred.current) {
            createOptionFromInputValue();
        }
        isInputPreviouslyBlurred.current = false;
    };

    const handleChangeSelect = (e) => {
        isInputPreviouslyBlurred.current = true;
        setInfoState({ ...infoState, address: { ...infoState.address, address: e } });
    };

    return (
        <PageModal active={active}>
            <PageHeaderModal title={'Thông tin khách hàng'} onClose={onClose}></PageHeaderModal>
            <div id="form-info-customer" className="h-[calc(100vh-90px)] pb-14 overflow-y-auto bg-gray-100 space-y-2">
                <div className="bg-gray-000">
                    <Card className="!space-y-2">
                        <CardBody>
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col form-validate relative">
                                    <input
                                        value={infoState ? infoState.name : ''}
                                        onChange={(e) => setInfoState({ ...infoState, name: e.target.value.slice(0, 50) })}
                                        type="text"
                                        placeholder="Họ tên người nhận"
                                        className="w-full border border-border-input rounded-[4px] py-2 px-2 outline-none focus:border-blue-brand-700 placeholder-[#808080]"
                                        data-empty="1"
                                        data-min-length="2"
                                    />
                                    <span className="absolute -top-1 right-1 text-red-brand-500 text-2xl">&lowast;</span>
                                    <span className="error-message text-xs"></span>
                                </div>
                                <div className="flex flex-col form-validate relative">
                                    <input
                                        value={infoState ? infoState.phone : ''}
                                        onChange={(e) => setInfoState({ ...infoState, phone: e.target.value.slice(0, 11) })}
                                        type="text"
                                        placeholder="Số điện thoại"
                                        className="w-full border border-border-input rounded-[4px] py-2 px-2 outline-none focus:border-blue-brand-700 placeholder-[#808080]"
                                        data-empty="1"
                                        data-phone-hotline="1"
                                    />
                                    <span className="absolute -top-1 right-1 text-red-brand-500 text-2xl">&lowast;</span>
                                    <span className="error-message text-xs"></span>
                                </div>
                                <div className="flex flex-col form-validate relative">
                                    <AsyncSelect
                                        value={infoState ? infoState.address.address : null}
                                        onChange={handleChangeSelect}
                                        cacheOptions
                                        loadOptions={loadOptions}
                                        placeholder="Nhập địa chỉ"
                                        noOptionsMessage={() => 'Vui lòng nhập địa chỉ'}
                                        loadingMessage={() => 'Đang tìm kiếm địa chỉ'}
                                        // onMenuClose={onMenuClose}
                                        inputValue={inputValue}
                                        onInputChange={setInputValue}
                                        classNames={{
                                            control: () => 'select-validate select-validate-address',
                                        }}
                                    />
                                    <span className="absolute -top-1 right-1 text-red-brand-500 text-2xl">&lowast;</span>
                                    <span className="error-message text-xs"></span>
                                </div>
                                <div>
                                    <textarea
                                        className="w-full border border-border-input rounded-[4px] py-2 px-2 outline-none focus:border-blue-brand-700 placeholder-[#808080]"
                                        rows="6"
                                        placeholder="Ghi chú khác (nếu có)"
                                        onChange={(e) => setInfoState({ ...infoState, address: { ...infoState.address, note: removeEmojis(e.target.value.slice(0, 255)) } })}
                                        value={infoState ? infoState.address.note : ''}
                                    ></textarea>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <TabBar>
                <Button className={`bg-custom-gradient hover:bg-custom-gradient-hover`} onClick={handleSubmit}>
                    <div className="text-white text-base uppercase font-bold mx-auto">Tiếp tục</div>
                </Button>
            </TabBar>
        </PageModal>
    );
};

export default PageInfo;
