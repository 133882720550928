import { Link } from "react-router-dom";
import images from "../../assets/images";
import config from "../../config";
import { useContext } from "react";
import { MenuContext } from "../../contexts/MenuContext";

function Footer() {
  const {
    menuState: { restaurant, restaurant_active },
    setModalBrand,
  } = useContext(MenuContext);

  return (
    <footer className="relative bg-[#151515] text-white">
      <div className="container mx-auto pt-6 pb-6">
        <div className="grid grid-cols-1 md:grid-cols-4">
          <div className="py-4 p-0 md:py-8 md:px-4 md:col-span-2">
            <h2 className="sm:text-xl text-[#FCFBFA] text-sm font-bold sm:text-left text-justify mb-6 flex items-center uppercase">
              {restaurant_active?.name}
            </h2>

            <div className="flex flex-col gap-6">
              <div className="flex items-center gap-2">
                <i className="fi-rr-marker icon-branch text-2xl text-orange-brand-900"></i>
                <span className="text-[#FCFBFA] md:text-base overflow-hidden text-ellipsis">
                  {restaurant_active.branches &&
                    restaurant_active.branches.length > 0 &&
                    restaurant_active.branches[0].address_full_text}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <i className="fi-rr-phone-flip icon-branch text-2xl text-orange-brand-900"></i>
                <span className="text-[#FCFBFA] md:text-base overflow-hidden text-ellipsis">
                  (028) 3783 928
                </span>
              </div>
              <div className="flex items-center gap-2">
                <i className="fi-rr-envelope icon-branch text-2xl text-orange-brand-900"></i>
                <span className="text-[#FCFBFA] md:text-base overflow-hidden text-ellipsis">
                  contact@domain.com
                </span>
              </div>
            </div>
          </div>

          <div className="py-4 p-0 md:py-8 md:px-4">
            <h2 className="sm:text-xl text-[#FCFBFA] text-sm font-bold sm:text-left text-justify mb-6 flex items-center uppercase">
              Thông tin
            </h2>

            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-2">
                <Link
                  to={config.routes.home}
                  className="text-[#FCFBFA] md:text-base overflow-hidden text-ellipsis"
                >
                  Điều kiện giao dịch
                </Link>
              </div>
              <div className="flex items-center gap-2">
                <Link
                  to={config.routes.home}
                  className="text-[#FCFBFA] md:text-base overflow-hidden text-ellipsis"
                >
                  Chính sách giao hàng
                </Link>
              </div>
              <div className="flex items-center gap-2">
                <Link
                  to={config.routes.home}
                  className="text-[#FCFBFA] md:text-base overflow-hidden text-ellipsis"
                >
                  Điều khoản bảo mật
                </Link>
              </div>
              <div className="flex items-center gap-2">
                <Link 
                  to={config.routes.home}
                  className="text-[#FCFBFA] md:text-base overflow-hidden text-ellipsis">
                  Phương thức thanh toán
                </Link>
              </div>
            </div>
          </div>

          <div className="py-4 p-0 md:py-8 md:px-4">
            <h2 className="sm:text-xl text-[#FCFBFA] text-sm font-normal sm:text-left text-justify mb-6 flex items-center uppercase">
              Cảm ơn bạn!
            </h2>

            <p className="text-[#FCFBFA] md:text-base">
              Để ghé thăm website của quán
            </p>

            <button className="mt-6 px-6 py-2 bg-[#F36A0F] text-[#FCFBFA] uppercase rounded-[34px]">
              Liên hệ Techres
            </button>
          </div>

          <img
            src={images.footerImg}
            alt="Footer Img"
            className="absolute z-10 right-0 bottom-4 max-w-[150px] md:max-w-[300px]"
          />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
