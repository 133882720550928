import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import HeaderMini from '../../layouts/Header/HeaderMini';
import { MenuContext } from '../../contexts/MenuContext';
import Card, { CardBody } from '../../components/Card';
import OrderCard from '../../components/Order';
import { OrderContext } from '../../contexts/OrderContext';
import images from '../../assets/images';
import { API_GET_LIST_ORDER, ENUM_METHOD_GET, ENUM_PROJECT_ID_JAVA_ORDER } from '../../contexts/constains';
import * as axiosTemplate from '../../utils/axiosTemplate';
import * as alertNotify from '../../utils/alertNotify';

const Order = () => {
    const navigate = useNavigate();

    const {
        menuState: { restaurant },
    } = useContext(MenuContext);

    const { orderState } = useContext(OrderContext);
    const [orders, setOrders] = useState([]);

    const handleHomeBack = () => {
        navigate(`/?qr_code=${restaurant.qr_code || ''}`);
    };

    useEffect(() => {
        const fetchGetListOrder = async (loading = false) => {
            const project = ENUM_PROJECT_ID_JAVA_ORDER;
            const method = ENUM_METHOD_GET;
            const api = API_GET_LIST_ORDER.sprintf(restaurant.id, -1, -1, -1, '', orderState.orders.length > 0 ? orderState.orders.map((item) => item.id).join(',') : '-1');
            const res = await axiosTemplate.get(project, method, api, { loading });
            switch (res.status) {
                case 200:
                    const mergedArray = [...res.data, ...orderState.orders].reduce((acc, item) => {
                        acc[item.id] = { ...acc[item.id], ...item };
                        return acc;
                    }, {});

                    const result = Object.values(mergedArray);
                    setOrders(result);
                    break;
                case 500:
                    alertNotify.push('Lỗi dữ liệu', 'Đóng');
                    break;
                default:
                    alertNotify.push(res.message, 'Đóng');
                    break;
            }
        };

        fetchGetListOrder(true);
        let interval = setInterval(fetchGetListOrder, 5000);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="pt-12">
            <HeaderMini title="Lịch sử đơn hàng" onClose={handleHomeBack} />
            <Card>
                <CardBody type="line">
                    <ul className="w-full grid gap-y-5 grid-cols-1 md:gap-x-[15px] lg:grid-cols-2 ">
                        {orders.length > 0 && orders.map((item, index) => <OrderCard key={index} data={item}></OrderCard>)}
                    </ul>
                    {orders.length === 0 && <img src={images.bgNoFood} alt="" className="w-28 mx-auto my-8" />}
                </CardBody>
            </Card>
        </div>
    );
};

export default Order;
