import { useContext } from "react";
import images from "../../assets/images";
import { MenuContext } from "../../contexts/MenuContext";
import { URL_ADS_MEDIA } from "../../contexts/constains";
import config from "../../config";

import { Link } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";

// Before Change
// const Header = ({ searchValue, onChangeSearchValue }) => {
//     const {
//         menuState: { restaurant, restaurant_active },
//         setModalBrand,
//     } = useContext(MenuContext);
//     return (
//         <>
//             <div className="relative">
//                 <div className="w-full h-auto">
//                     <img
//                         src={URL_ADS_MEDIA + restaurant.banner}
//                         onError={({ currentTarget }) => {
//                             currentTarget.onerror = null;
//                             currentTarget.src = images.bgEmpty;
//                         }}
//                         className="aspect-[2/1] object-cover bg-gray-600 w-full"
//                         alt=""
//                     />
//                 </div>
//                 <div className="absolute bottom-6 right-0 left-0">
//                     <div className="container mx-auto">
//                         <div className="bg-gray-000 rounded-b10 p-[10px] shadow-cart space-y-3">
//                             <div className="flex items-center">
//                                 <img
//                                     src={URL_ADS_MEDIA + restaurant.logo}
//                                     onError={({ currentTarget }) => {
//                                         currentTarget.onerror = null;
//                                         currentTarget.src = images.foodError;
//                                     }}
//                                     className="w-10 h-10 object-cover mr-2 rounded-b10"
//                                     alt=""
//                                 />
//                                 <h1 className="text-base font-bold">{restaurant?.name || ''}</h1>
//                             </div>
//                             <div className="flex items-center" onClick={() => setModalBrand(1)}>
//                                 <span className="mr-1 whitespace-nowrap flex text-blue-brand-700">
//                                     <i className="fi-rr-bank icon-brand" data-toggle="tooltip" data-placement="top" data-original-title="Thương hiệu"></i>
//                                 </span>
//                                 <span className="overflow-hidden text-ellipsis whitespace-nowrap flex-1">{restaurant_active.name}</span>
//                                 <span className="ml-1 whitespace-nowrap">
//                                     <i className="fi-rr-angle-small-right text-gray-600"></i>
//                                 </span>
//                             </div>
//                             <div className="flex items-center" onClick={() => setModalBrand(2)}>
//                                 <span className="mr-1 whitespace-nowrap flex text-green-brand-600">
//                                     <i className="fi-rr-marker icon-branch" data-toggle="tooltip" data-placement="top" data-original-title="Chi nhánh"></i>
//                                 </span>
//                                 {restaurant.lat !== 0 && restaurant.lng !== 0 && (
//                                     <span className="mr-1 whitespace-nowrap bg-green-brand-000 text-green-600 px-2 rounded-md font-medium">
//                                         {restaurant_active.branches && restaurant_active.branches.length > 0 && Math.round(restaurant_active.branches[0].distance_km * 100) / 100 + ' km'}
//                                     </span>
//                                 )}
//                                 <span className="overflow-hidden text-ellipsis whitespace-nowrap flex-1">
//                                     {restaurant_active.branches && restaurant_active.branches.length > 0 && restaurant_active.branches[0].address_full_text}
//                                 </span>
//                                 <span className="ml-1 whitespace-nowrap">
//                                     <i className="fi-rr-angle-small-right text-gray-600"></i>
//                                 </span>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="w-full h-auto flex items-center justify-center shadow-default bg-gray-000 sticky top-0 z-10">
//                 <div className="container mx-auto h-[50px] flex py-[10px] gap-[10px] items-center">
//                     <Link to={config.routes.orders} className="flex items-center gap-1 h-8 px-2 rounded-lg border border-gray-300">
//                         <i className="fi-rr-time-fast text-lg flex"></i>
//                         <span>Lịch sử đơn hàng</span>
//                     </Link>
//                     <div className="w-full flex-1">
//                         <div className="relative flex px-[10px] py-[5px] bg-gray-100 rounded-lg border border-gray-300">
//                             <img src={images.searchIcon} alt="" className="absolute top-1/2 -translate-y-1/2 left-[6px]" />
//                             <input type="text" value={searchValue} onChange={onChangeSearchValue} placeholder="Tìm kiếm" className="w-full bg-gray-100 pl-5 placeholder-gray-400" />
//                             {searchValue.length > 0 && (
//                                 <i onClick={() => onChangeSearchValue({ target: { value: '' } })} className="fi-sr-cross-circle text-gray-600 absolute top-1/2 -translate-y-1/2 right-3"></i>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// After Change
const Header = ({ searchValue, onChangeSearchValue, handleOpenCart }) => {
  const {
    menuState: { restaurant, restaurant_active },
    setModalBrand,
  } = useContext(MenuContext);

  const {
    cartState: { carts, total },
  } = useContext(CartContext);

  return (
    <div className="fixed top-0 z-20 w-full bg-[#00141680] h-[70px]">
      <div className="container mx-auto flex items-center justify-between h-full">
        {/* Logo */}
        <div className="hidden md:flex items-center">
          <img
            src={URL_ADS_MEDIA + restaurant.logo}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = images.foodError;
            }}
            className="w-12 h-12 object-cover mr-2 rounded-full cursor-pointer"
            alt="Restaurant Logo"
          />
        </div>

        <div className="flex-1 flex justify-end gap-2 md:gap-8">
          {/* Search Bar */}
          <div className="relative flex items-center bg-white rounded-full overflow-hidden w-full max-w-md">
            <img
              src={images.searchIcon}
              alt=""
              className="absolute top-1/2 -translate-y-1/2 left-[8px]"
            />
            <input
              type="text"
              placeholder="Tìm kiếm tên, mã sản phẩm"
              value={searchValue}
              onChange={onChangeSearchValue}
              className="px-8 py-2 w-full focus:outline-none"
            />
          </div>

          {/* Cart Icon */}
          <div className="relative flex items-center cursor-pointer" onClick={handleOpenCart}>
            <img
              src={images.shoppingCartIcon}
              alt=""
              className="object-cover"
            />
            {carts && carts.length > 0 ? (
              <span className="absolute -top-2 -right-2 bg-red-brand-500 text-white rounded-full text-xs w-6 h-6 flex items-center justify-center">
                +{carts.length}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
