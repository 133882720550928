const images = {
    shoppingCartIcon: require('../images/shopping-cart.png'),
    footerImg: require('../images/image_footer.png'),
    prevImg: require('../images/arrow-left.png'),
    nextImg: require('../images/arrow-right.png'),
    categoryPrevImg: require('../images/category-chevron-left.png'),
    categoryNextImg: require('../images/category-chevron-right.png'),
    radioCheckedImg: require('../images/radio_checked.png'),
    radioNormalImg: require('../images/radio_normal.png'),
    searchIcon: require('../images/search-icon.svg').default,
    tagIcon: require('../images/tags.svg').default,
    payosLogo: require('../images/payos-logo.svg').default,
    foodImg: require('../images/food.png'),
    emptyCartImg: require('../images/Empty-Cart.jfif'),
    foodError: require('../images/foodError.jpg'),
    loading: require('../images/LOADING.gif'),
    bgHeader: require('../images/bg-header.jpg'),
    bgNoFood: require('../images/no-food.jpg'),
    vietqrLogo: require('../images/vietqr.png'),
    napasLogo: require('../images/napas.png'),
    mbLogo: require('../images/Logo_MB_new.png'),
    bgEmpty: require('../images/bg-empty.png'),
};

export default images;
