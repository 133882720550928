const Button = ({ className, disabled = false, children, onClick }) => {
  return (
    <button
      className={`w-full flex items-center justify-between px-5 py-3  ${
        disabled ? "bg-gray-200" : "bg-green-brand-000"
      } rounded-full ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
