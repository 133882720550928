import { Link } from 'react-router-dom';
import Tag from '../Tag/Tag';
import { URL_ADS_MEDIA } from '../../contexts/constains';
import images from '../../assets/images';
import { formatNumber } from '../../utils/currency';
import { deliveryStatusRender } from '../../utils';
import { useContext } from 'react';
import { MenuContext } from '../../contexts/MenuContext';

const Order = ({ data }) => {
    const {
        menuState: { restaurant },
    } = useContext(MenuContext);
    return (
        <div className="shadow-cart space-x-[10px] relative flex rounded-b10 bg-gray-000">
            <Link to={`/order/${data.id}`} className="w-full h-full p-3">
                <div className="border-b border-gray-300 pb-3">
                    <Tag className={'bg-gray-200 text-gray-600'}>
                        <span>
                            <b>{data.created_at.split(' ')[1]}</b> Ngày <b>{data.created_at.split(' ')[0]}</b>
                        </span>
                    </Tag>
                    <Tag className={`${deliveryStatusRender(data.customer_order_status).bg} ${deliveryStatusRender(data.customer_order_status).color}`}>
                        <span className="font-medium">{deliveryStatusRender(data.customer_order_status).text}</span>
                    </Tag>
                </div>
                <div className="border-b border-gray-300 py-3">
                    <div className="grid grid-cols-2 gap-y-1">
                        <span>Mã đơn</span>
                        <span className="font-medium">#{data.id}</span>
                        <span>Món ăn</span>
                        <span className="font-medium">{data.count_food} món</span>
                        <span>Tổng thanh toán</span>
                        <span className="font-medium">{formatNumber(data.total_amount + data.shipping_fee)}</span>
                    </div>
                </div>
                <div className="pt-3 flex items-center">
                    <img
                        src={URL_ADS_MEDIA + restaurant.logo}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = images.foodError;
                        }}
                        className="w-16 h-16 object-cover mr-2 rounded-b10"
                        alt=""
                    />
                    <div className="flex-1 w-[calc(100%-64px-12px)] space-y-1">
                        <h1 className="text-base font-bold">{restaurant.name || ''}</h1>
                        <div className="flex items-center">
                            <span className="overflow-hidden text-ellipsis whitespace-nowrap flex-1 text-xs text-gray-600">{data.branch_address || ''}</span>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default Order;
