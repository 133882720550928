import { enableScroll } from '../../utils/scrollBody';

const Alert = () => {
    const handleCloseAlert = () => {
        let alertEl = document.getElementById('alert-notify-app');
        alertEl.querySelector('.alert-overlay').classList.add('invisible', 'opacity-0', 'pointer-events-none');
        alertEl.querySelector('.alert-popup').classList.add('scale-0', 'opacity-0');
        enableScroll();
    };
    return (
        <div id="alert-notify-app">
            <div className="fixed inset-0 z-50 bg-gray-900-16 transition ease-out duration-700 invisible opacity-0 pointer-events-none alert-overlay"></div>
            <div className="fixed inset-0 z-50 flex items-center justify-center scale-0 opacity-0 transition ease-out duration-300 delay-0 alert-popup">
                <div className="max-w-xs min-w-[320px] bg-gray-000 rounded-[10px] overflow-hidden   ">
                    <div className="p-5 text-center">
                        <span className="alert-title"></span>
                    </div>
                    <div></div>
                    <div className="w-full text-lg flex">
                        <button className="bg-gray-300 text-red-brand-500 flex-1 py-3 alert-cancel" onClick={handleCloseAlert}></button>
                        <button className="bg-orange-brand-900 text-gray-000 flex-1 py-3 alert-accept" onClick={handleCloseAlert}></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Alert;
