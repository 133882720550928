import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HeaderMini from '../../layouts/Header/HeaderMini';
import images from '../../assets/images';

const Tracking = () => {
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [urlTracking, setUrlTracking] = useState('');

    useEffect(() => {
        let url = searchParams.get('url');
        if (url) {
            setUrlTracking(url);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="pt-12">
            <HeaderMini title="Theo dõi đơn hàng" onClose={() => navigate(-1)} />
            {urlTracking !== '' && <iframe className="w-screen h-[calc(100vh-108px)]" title="Theo dõi đơn hàng" src={urlTracking}></iframe>}
            {urlTracking === '' && <img src={images.bgNoFood} alt="" className="w-28 mx-auto my-8" />}
        </div>
    );
};

export default Tracking;
