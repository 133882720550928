import { createContext, useReducer } from 'react';

import { orderReducer } from '../reducers/OrderReducer';
import storage from '../utils/storage';
import { ORDER_STORAGE_KEY, PUSH_ORDER } from './constains';

export const OrderContext = createContext();

const OrderProvider = ({ children }) => {
    const [orderState, dispatch] = useReducer(orderReducer, {
        orders: storage.get(ORDER_STORAGE_KEY, []),
    });

    const pushOrder = async (newOrder) => {
        dispatch({ type: PUSH_ORDER, payload: newOrder });
        return true;
    };

    const orderContextData = {
        orderState,
        pushOrder,
    };

    return <OrderContext.Provider value={orderContextData}>{children}</OrderContext.Provider>;
};

export default OrderProvider;
