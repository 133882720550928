// Before Change
// const TabBar = ({ children, height = "h-tab-bar-df" }) => {
//     return (
//         <div className={`${height} w-full fixed bottom-0 bg-gray-000 shadow-cart rounded-t-[10px]`}>
//             <div className="container mx-auto h-full w-full space-y-2 pt-[17px]">{children}</div>
//         </div>
//     );
// };

// After Change
const TabBar = ({ children, height = "h-tab-bar-df",className = "" }) => {
    return (
        <div className={`${height} w-full fixed bottom-0 bg-gray-000 shadow-cart rounded-t-[10px]`}>
            <div className={`${className} h-full w-full space-y-2 pt-[22px] px-4`}>{children}</div>
        </div>
    );
};

export default TabBar;
