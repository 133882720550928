import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Header from "../../layouts/Header";

import Button from "../../components/Button";
import TabBar from "../../components/TabBar";
import Cart from "../../components/Cart/Cart";
import Content from "./Content";

import { CartContext } from "../../contexts/CartContext";
import { MenuContext } from "../../contexts/MenuContext";

import * as axiosTemplate from "../../utils/axiosTemplate";
import { formatNumber, generateRandomString } from "../../utils/currency";
import * as alertNotify from "../../utils/alertNotify";

import {
  API_QR_CODE_ORDER,
  CART_MODAL_LOCATION,
  DEVICE_ID_KEY,
  ENUM_METHOD_POST,
  ENUM_PROJECT_ID_JAVA_ORDER,
  HOME_LOCATION,
} from "../../contexts/constains";
import toast from "react-hot-toast";
import { lockScroll } from "../../utils/scrollBody";
import storage from "../../utils/storage";
import Hero from "../../components/Hero";
import Category from "../../components/Category";
import Footer from "../../layouts/Footer";
import FoodNew from "../../components/FoodNew";

// Before Change
// const Home = () => {
//     let [searchParams, setSearchParams] = useSearchParams();
//     const { hash } = useLocation();
//     const navigate = useNavigate();

//     const {
//         cartState: { carts, total },
//         clearFood,
//         resetCustomer,
//     } = useContext(CartContext);

//     const {
//         setRestaurant,
//         setRestaurantActive,
//         menuState: { restaurant, restaurant_active },
//     } = useContext(MenuContext);

//     let [cartActive, setCartActive] = useState(false);
//     let [searchValue, setSearchValue] = useState('');

//     const handleOpenCart = () => {
//         setCartActive(true);
//         window.location.hash = CART_MODAL_LOCATION;
//     };

//     const handleCloseCart = () => {
//         navigate(-1);
//         // setCartActive(false);
//         // window.location.hash = '#';
//     };

//     useEffect(() => {
//         let qrCode = searchParams.get('qr_code');
//         if (restaurant.qr_code && (!qrCode || qrCode === '')) {
//             qrCode = restaurant.qr_code;
//             setSearchParams({ qr_code: qrCode });
//         } else if (qrCode !== restaurant.qr_code) {
//             localStorage.clear();
//             setRestaurant({});
//             setRestaurantActive({});
//             clearFood();
//             resetCustomer();
//         }

//         if (qrCode && qrCode !== '') {
//             let latitude = 0,
//                 longitude = 0;

//             const fetchQrCode = async (qrCode) => {
//                 const project = ENUM_PROJECT_ID_JAVA_ORDER;
//                 const method = ENUM_METHOD_POST;

//                 let device_id = storage.get(DEVICE_ID_KEY, generateRandomString(20));
//                 storage.set(DEVICE_ID_KEY, device_id);

//                 const res = await axiosTemplate.post(project, method, API_QR_CODE_ORDER, {
//                     device_id: device_id,
//                     qr_code: qrCode,
//                     lat: latitude,
//                     lng: longitude,
//                 });
//                 switch (res.status) {
//                     case 200:
//                         if (res.data.restaurant_brands.length === 0) {
//                             alertNotify.push('Nhà hàng hiện chưa được bật tính năng này!', 'Đóng');
//                             return;
//                         }
//                         res.data.qr_code = qrCode;
//                         res.data.lat = latitude;
//                         res.data.lng = longitude;
//                         setRestaurant(res.data);
//                         break;
//                     case 500:
//                         alertNotify.push('Lỗi dữ liệu', 'Đóng');
//                         break;
//                     default:
//                         alertNotify.push(res.message, 'Đóng');
//                         break;
//                 }
//             };

//             if (navigator.geolocation) {
//                 navigator.geolocation.getCurrentPosition(
//                     function (position) {
//                         latitude = position.coords.latitude;
//                         longitude = position.coords.longitude;
//                         fetchQrCode(qrCode);
//                     },
//                     function (error) {
//                         if (error.code === 3) {
//                             toast('Bật vị trí trên thiết bị của bạn để đo khoảng cách');
//                         }
//                         fetchQrCode(qrCode);
//                     },
//                     {
//                         timeout: 5000,
//                     },
//                 );
//             } else {
//                 fetchQrCode(qrCode);
//             }
//         } else {
//             alertNotify.push('Mã QR Code không hợp lệ. Xin vui lòng thử lại', 'Đóng');
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [searchParams]);

//     useEffect(() => {
//         if (Object.keys(restaurant_active).length > 0 && restaurant_active.branches.length > 0) {
//             document.title = restaurant_active.branches[0]?.name;
//         } else if (Object.keys(restaurant).length > 0) {
//             document.title = restaurant.name;
//         }
//     }, [restaurant_active, restaurant]);

//     useEffect(() => {
//         if (hash !== `#${CART_MODAL_LOCATION}` && cartActive) {
//             setCartActive(false);
//         } else if (hash === `#${CART_MODAL_LOCATION}` && !cartActive) {
//             setCartActive(true);
//         }

//         if (hash === '') lockScroll(HOME_LOCATION);
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [hash]);

//     useEffect(() => {
//         if (cartActive) lockScroll(CART_MODAL_LOCATION);
//     }, [cartActive]);

//     return (
//         <div className="pb-[90px]">
//             <Header searchValue={searchValue} onChangeSearchValue={(event) => setSearchValue(event.target.value)} />
//             <Content searchValue={searchValue} />
//             <span className="text-gray-400 block text-center text-xs mb-3">© 2023 TECHRES.VN</span>
//             <TabBar>
//                 <Button onClick={handleOpenCart}>
//                     <div className="text-green-brand-600 text-base">
//                         <b>Giỏ hàng</b>
//                         <span className="text-gray-900">&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
//                         <span>{carts.length} món</span>
//                     </div>
//                     <span className="text-green-brand-600 text-xl font-bold">{formatNumber(total)}</span>
//                 </Button>
//             </TabBar>
//             <Cart active={cartActive} onClose={handleCloseCart} />
//         </div>
//     );
// };

// After Change
const Home = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { hash } = useLocation();
  const navigate = useNavigate();

  const {
    cartState: { carts, total },
    clearFood,
    resetCustomer,
  } = useContext(CartContext);

  const {
    setRestaurant,
    setRestaurantActive,
    menuState: { restaurant, restaurant_active },
  } = useContext(MenuContext);

  let [cartActive, setCartActive] = useState(false);
  let [searchValue, setSearchValue] = useState("");
  let [categoryValue, setCategoryValue] = useState(0);
  let [isLoadContent,setIsLoadContent] = useState(false);
  const [menuOrder, setMenuOrder] = useState([]);
  const [foodNew, setFoodNew] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [foodOptionModalActive, setFoodOptionModalActive] = useState({
    active: false,
    data: {},
  });

  const handleOpenCart = () => {
    setCartActive(true);
    window.location.hash = CART_MODAL_LOCATION;
  };

  const handleCloseCart = () => {
    navigate(-1);
    // setCartActive(false);
    // window.location.hash = '#';
  };

  useEffect(() => {
    let qrCode = searchParams.get("qr_code");
    if (restaurant.qr_code && (!qrCode || qrCode === "")) {
      qrCode = restaurant.qr_code;
      setSearchParams({ qr_code: qrCode });
    } else if (qrCode !== restaurant.qr_code) {
      localStorage.clear();
      setRestaurant({});
      setRestaurantActive({});
      clearFood();
      resetCustomer();
    }

    if (qrCode && qrCode !== "") {
      let latitude = 0,
        longitude = 0;

      const fetchQrCode = async (qrCode) => {
        const project = ENUM_PROJECT_ID_JAVA_ORDER;
        const method = ENUM_METHOD_POST;

        let device_id = storage.get(DEVICE_ID_KEY, generateRandomString(20));
        storage.set(DEVICE_ID_KEY, device_id);

        const res = await axiosTemplate.post(
          project,
          method,
          API_QR_CODE_ORDER,
          {
            device_id: device_id,
            qr_code: qrCode,
            lat: latitude,
            lng: longitude,
          }
        );
        switch (res.status) {
          case 200:
            if (res.data.restaurant_brands.length === 0) {
              alertNotify.push(
                "Nhà hàng hiện chưa được bật tính năng này!",
                "Đóng"
              );
              return;
            }
            res.data.qr_code = qrCode;
            res.data.lat = latitude;
            res.data.lng = longitude;
            setRestaurant(res.data);
            break;
          case 500:
            alertNotify.push("Lỗi dữ liệu", "Đóng");
            break;
          default:
            alertNotify.push(res.message, "Đóng");
            break;
        }
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            latitude = position.coords.latitude;
            longitude = position.coords.longitude;
            fetchQrCode(qrCode);
          },
          function (error) {
            if (error.code === 3) {
              toast("Bật vị trí trên thiết bị của bạn để đo khoảng cách");
            }
            fetchQrCode(qrCode);
          },
          {
            timeout: 5000,
          }
        );
      } else {
        fetchQrCode(qrCode);
      }
    } else {
      alertNotify.push("Mã QR Code không hợp lệ. Xin vui lòng thử lại", "Đóng");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (
      Object.keys(restaurant_active).length > 0 &&
      restaurant_active.branches.length > 0
    ) {
      document.title = restaurant_active.branches[0]?.name;
    } else if (Object.keys(restaurant).length > 0) {
      document.title = restaurant.name;
    }
  }, [restaurant_active, restaurant]);

  useEffect(() => {
    if (hash !== `#${CART_MODAL_LOCATION}` && cartActive) {
      setCartActive(false);
    } else if (hash === `#${CART_MODAL_LOCATION}` && !cartActive) {
      setCartActive(true);
    }

    if (hash === "") lockScroll(HOME_LOCATION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  useEffect(() => {
    if (cartActive) lockScroll(CART_MODAL_LOCATION);
  }, [cartActive]);

  return (
    <>
      <Header
        searchValue={searchValue}
        onChangeSearchValue={(event) => setSearchValue(event.target.value)}
        handleOpenCart={handleOpenCart}
      />
      <Hero />
      <Category categoryList={categoryList} setCategoryList={setCategoryList} setCategoryValue={setCategoryValue} isLoadContent={isLoadContent}/>

      {/* Food New */}
      {/* <FoodNew food={foodNew} categoryValue={categoryValue} foodOptionModalActive={foodOptionModalActive} setFoodOptionModalActive={setFoodOptionModalActive}/> */}
      
      {/* Food By Category */}
      <Content
        searchValue={searchValue}
        menuOrder={menuOrder}
        setMenuOrder={setMenuOrder}
        categoryList={categoryList}
        setFoodNew={setFoodNew}
        categoryValue={categoryValue}
        setCategoryList={setCategoryList}
        foodOptionModalActive={foodOptionModalActive} 
        setFoodOptionModalActive={setFoodOptionModalActive}
        setIsLoadContent={setIsLoadContent}
      />
      <Cart active={cartActive} onClose={handleCloseCart} />
      <Footer />
    </>
  );
};

export default Home;
