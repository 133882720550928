import axios from 'axios';

import { ENUM_PREFIX_JAVA_ORDER, ENUM_PROJECT_ID_CITY, ENUM_PROJECT_ID_JAVA_ORDER, ENUM_PROJECT_ID_PUBLIC } from '../contexts/constains';

import * as loading from '../utils/loading';
import * as alertNotify from '../utils/alertNotify';

const request = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { Accept: 'application/json' },
    timeout: 10000,
});

export const get = async (project, method, path, options = { loading: true }) => {
    const client = getClientGateway(project, method, path);
    let api;
    switch (project) {
        case ENUM_PROJECT_ID_CITY:
            api = process.env.REACT_APP_DOMAIN_GATEWAY2 + client.api;
            break;
        case ENUM_PROJECT_ID_PUBLIC:
            api = client.api;
            break;
        default:
            api = process.env.REACT_APP_DOMAIN_GATEWAY + client.api;
            break;
    }
    if (options.loading) loading.push();
    let res;
    try {
        res = await request.get(api, client.client);
        res.data.config = `ProjectId: ${project} Method: ${method} Request:${api} Body:null`;
        console.log(res);
        if (options.loading) loading.pop();
        return res.data;
    } catch (error) {
        error.config.config = `ProjectId: ${project} Method: ${method} Request:${api} Body:null`;
        console.log(error);
        if (options.loading) loading.pop();
        alertNotify.push('Lỗi dữ liệu', 'Đóng');
        return { status: 500 };
    }
};

export const post = async (project, method, path, data, options = { loading: true }) => {
    const client = getClientGateway(project, method, path);
    let api;
    switch (project) {
        case ENUM_PROJECT_ID_CITY:
            api = process.env.REACT_APP_DOMAIN_GATEWAY2 + client.api;
            break;
        case ENUM_PROJECT_ID_PUBLIC:
            api = client.api;
            break;
        default:
            api = process.env.REACT_APP_DOMAIN_GATEWAY + client.api;
            break;
    }
    if (options.loading) loading.push();
    let res;
    try {
        res = await request.post(api, data, client.client);
        res.data.config = `ProjectId: ${project} Method: ${method} Request:${api} Body:${JSON.stringify(data)}`;
        console.log(res);
        if (options.loading) loading.pop();
        return res.data;
    } catch (error) {
        error.config.config = `ProjectId: ${project} Method: ${method} Request:${api} Body:${JSON.stringify(data)}`;
        console.log(error);
        if (options.loading) loading.pop();
        alertNotify.push('Lỗi dữ liệu', 'Đóng');
        return { status: 500 };
    }
};

const getClientGateway = (project, method, api) => {
    switch (project) {
        case ENUM_PROJECT_ID_JAVA_ORDER:
            api = '/api/' + ENUM_PREFIX_JAVA_ORDER + api;
            break;
        case ENUM_PROJECT_ID_CITY:
            api = '/api' + api;
            break;
        case ENUM_PROJECT_ID_PUBLIC:
            break;
        default:
            api = '/api/' + ENUM_PREFIX_JAVA_ORDER + api;
            break;
    }

    let client = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ProjectId: project,
            Method: method,
            'Access-Control-Allow-Origin': 'http://localhost:3000',
            'Access-Control-Allow-Credentials': true,
        },
    };
    return { client, api };
};

export default request;
