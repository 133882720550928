// Context API Reducer
export const ADD_FOOD = 'ADD_FOOD';
export const EDIT_FOOD = 'EDIT_FOOD';
export const REMOVE_FOOD = 'REMOVE_FOOD';
export const CLEAR_FOOD = 'CLEAR_FOOD';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const RESET_CUSTOMER = 'RESET_CUSTOMER';
export const SET_RESTAURANT = 'SET_RESTAURANT';
export const SET_RESTAURANT_ACTIVE = 'SET_RESTAURANT_ACTIVE';
export const SET_MODAL_BRAND = 'SET_MODAL_BRAND';
export const SET_LOCATION = 'SET_LOCATION';
export const RESTAURANT_STORAGE_KEY = 'RESTAURANT_STORAGE_KEY';
export const RESTAURANT_ACTIVE_STORAGE_KEY = 'RESTAURANT_ACTIVE_STORAGE_KEY';
export const CART_STORAGE_KEY = 'CART_STORAGE_KEY';
export const TOTAL_CART_STORAGE_KEY = 'TOTAL_CART_STORAGE_KEY';
export const INFO_CUSTOMER_STORAGE_KEY = 'INFO_CUSTOMER_STORAGE_KEY';
export const ORDER_STORAGE_KEY = 'ORDER_STORAGE_KEY';
export const PUSH_ORDER = 'PUSH_ORDER';
export const DEVICE_ID_KEY = 'DEVICE_ID_KEY';

// PROJECT_ID VERSION PREFIX
export const ENUM_PREFIX_JAVA_ORDER = 'v1';

// API
export const API_QR_CODE_ORDER = '/public/customer-order-online/qr-code';
export const API_FOOD_HEALTH_CHECK = '/food-health-check/customer-order/check?restaurant_id={0}&restaurant_brand_id={1}&branch_id={2}';
export const API_GET_MENU_ORDER = '/public/foods/customer-order-online/menu?restaurant_id={0}&restaurant_brand_id={1}&branch_id={2}&category_type={3}&limit={4}&page={5}';
export const API_CREATE_BOOKING_ORDER = '/public/customer-order-online/create';
export const API_GET_DETAIL_ORDER = '/public/customer-order-online/{0}';
export const API_GET_LIST_ORDER = '/public/customer-order-online?restaurant_id={0}&restaurant_brand_id={1}&branch_id={2}&customer_order_type={3}&customer_order_status={4}&customer_order_ids={5}';
export const API_GET_ESTIMATE_FEE = '/public/customer-order-online/estimate-fee?lat={0}&lng={1}&address={2}&branch_id={3}';
export const API_GET_CITIES_DATA = '/cities';
export const API_GET_DISTRICTS_DATA = '/districts?city_id={0}';
export const API_GET_WARDS_DATA = '/wards?district_id={0}';
export const API_GET_MAP4D = 'https://api.map4d.vn/sdk/place/text-search?key={0}&text={1}';

// ENUM GATEWAY METHOD
export const ENUM_METHOD_GET = 0;
export const ENUM_METHOD_POST = 1;

// PROJECT_ID
export const ENUM_PROJECT_ID_JAVA_ORDER = 8403;
export const ENUM_PROJECT_ID_HEALTH_CHECK = 1408;
export const ENUM_PROJECT_ID_CITY = 1411;
export const ENUM_PROJECT_ID_PUBLIC = 0;

// DOMAIN IMAGE
export const URL_ADS_MEDIA = process.env.REACT_APP_RESOURCE_DOMAIN_URL;

// HASH LOCATION
export const HOME_LOCATION = 'home-location';
export const FOOD_MODAL_LOCATION = 'food-modal-location';
export const FOOD_MODAL_UPDATE_LOCATION = 'food-modal-update-location';
export const CART_MODAL_LOCATION = 'cart-modal-location';
export const PAGE_INFO_LOCATION = 'page-info-location';
export const PAGE_CHECKOUT_LOCATION = 'page-checkout-location';
export const PAGE_PAYMENT_LOCATION = 'page-payment-location';
