import { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

import images from "../../assets/images";

import Button from "../Button";
import Card, { CardBody, CardTitle } from "../Card";
import TabBar from "../TabBar";
import TabModal, { TabModalHeader } from "../TabModal";

import { CartContext } from "../../contexts/CartContext";

import { formatNumber, takeDecimalNumber } from "../../utils/currency";
import * as alertNotify from "../../utils/alertNotify";
import Tag from "../Tag/Tag";
import { URL_ADS_MEDIA } from "../../contexts/constains";
import { removeEmojis } from "../../utils";

// Before Change
// const Food = ({ data, type = 'line', onOpenFoodOption }) => {
//     const isGridType = type === 'grid';
//     const isLineType = type === 'line';

//     const {
//         cartState: { carts },
//         removeFood,
//     } = useContext(CartContext);
//     const check = carts.find((item) => item.id === data.id);

//     const handleFoodOption = () => {
//         if (check && check.length !== 0) {
//             alertNotify.push('Bạn có muốn xóa lựa chọn này ra khỏi giỏ hàng ?', 'Đóng', 'Đồng ý', () => removeFood(data.id));
//         } else {
//             onOpenFoodOption();
//         }
//     };

//     const renderActionButton = (
//         <button className="absolute p-2 inline-flex items-center justify-center rounded-md border border-gray-000 bg-orange-brand-300 bottom-1 right-1">
//             <i className={`${check && check.length !== 0 ? 'fi-sr-minus' : 'fi-sr-plus'} text-orange-brand-900`}></i>
//         </button>
//     );

//     return (
//         <div onClick={handleFoodOption} className={`${isGridType ? 'flex-col' : 'shadow-cart space-x-[10px] p-1'} relative flex rounded-b10 bg-gray-000`}>
//             <div className={`relative rounded-md overflow-hidden ${isGridType ? 'aspect-square w-full h-full' : 'w-20 h-20'}`}>
//                 <img
//                     src={URL_ADS_MEDIA + data.avatar}
//                     alt=""
//                     className="w-full h-full object-cover"
//                     onError={({ currentTarget }) => {
//                         currentTarget.onerror = null;
//                         currentTarget.src = images.foodError;
//                     }}
//                 />
//                 {isGridType && renderActionButton}
//             </div>
//             <div className="pt-1 flex-1">
//                 <span className="tracking-[0.25px]" style={{ overflowWrap: 'anywhere' }}>
//                     {data.name}
//                 </span>
//                 <ul className="flex flex-col text-gray-600 text-xs">
//                     {data.food_in_combo &&
//                         data.food_in_combo.length > 0 &&
//                         data.food_in_combo.map((option, index) => (
//                             <li key={index} style={{ overflowWrap: 'anywhere' }}>
//                                 + {option.name}&nbsp;<span className="text-orange-brand-900">x{option.combo_quantity}</span>
//                             </li>
//                         ))}
//                 </ul>
//                 <div className="flex space-x-[5px]">
//                     <span className="text-lg font-bold text-orange-brand-900">{formatNumber(data.price)}</span>
//                     {/* {data.sale_price !== 0 && (
//                         <div className="flex-1 space-x-[5px] flex items-center">
//                             <span className="text-xs tracking-[0.25px] text-gray-600 line-through">{formatNumber(data.original_price)}</span>
//                             <span>
//                                 <img src={images.tagIcon} alt="" />
//                             </span>
//                         </div>
//                     )} */}
//                 </div>
//             </div>
//             {isLineType && renderActionButton}
//         </div>
//     );
// };

// After Change
const Food = ({ data, type = "line", onOpenFoodOption, isSlider = false }) => {
  const isGridType = type === "grid";
  const isLineType = type === "line";

  const {
    cartState: { carts },
    removeFood,
  } = useContext(CartContext);
  const check = carts.find((item) => item.id === data.id);

  const handleFoodOption = () => {
    if (check && check.length !== 0) {
      alertNotify.push(
        "Bạn có muốn xóa lựa chọn này ra khỏi giỏ hàng ?",
        "Đóng",
        "Đồng ý",
        () => removeFood(data.id)
      );
    } else {
      onOpenFoodOption();
    }
  };

  const renderActionButton = (
    <button className="p-3 inline-flex items-center justify-center rounded-md border border-gray-000 bg-[#FBD1B5]">
      <i
        className={`${
          check && check.length !== 0 ? "fi-sr-minus" : "fi-sr-plus"
        } text-orange-brand-900`}
      ></i>
    </button>
  );

  return (
    <div
      onClick={handleFoodOption}
      className={`${isGridType ? "flex-col" : "space-x-[14px] p-4"} ${
        !isGridType && !isSlider ? "shadow-cart" : ""
      } relative flex rounded-b10 bg-[#FCFBFA]`}
    >
      <div
        className={`relative rounded-md overflow-hidden ${
          isGridType ? "aspect-square w-full h-full" : "w-20 h-20"
        }`}
      >
        <img
          src={URL_ADS_MEDIA + data.avatar}
          alt=""
          className="w-full h-full object-cover"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = images.foodError;
          }}
        />
        {isGridType && renderActionButton}
      </div>
      <div className="flex-1 w-full">
        <span
          className="tracking-[0.25px] text-base font-medium text-[#001416]"
          style={{ overflowWrap: "anywhere" }}
        >
          {data.name}
        </span>

        {data.food_in_combo && data.food_in_combo.length > 0 ? (
          <ul className="flex flex-col text-gray-600 text-xs">
            {data.food_in_combo.map((option, index) => (
              <li key={index} style={{ overflowWrap: "anywhere" }}>
                + {option.name}&nbsp;
                <span className="text-orange-brand-900">
                  x{option.combo_quantity}
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <p className="overflow-hidden text-ellipsis whitespace-nowrap font-normal text-[#6B7280] max-w-[300px] min-h-5">
            {data.description}
          </p>
        )}

        <div className="flex space-x-[5px] items-center justify-between">
          <span className="text-lg font-bold text-orange-brand-900">
            {formatNumber(data.price)}
          </span>
          {isLineType && renderActionButton}
        </div>
      </div>
    </div>
  );
};

// Before Change
// const FoodOptionModal = ({ active, data, onClose, type = 0 }) => {
//   const { addFood, editFood } = useContext(CartContext);
//   const [quantityFood, setQuantityFood] = useState(1);
//   const [totalFood, setTotalFood] = useState(0);
//   const [foodOption, setFoodOption] = useState([]);
//   const [noteFood, setNoteFood] = useState("");

//   const noteRef = useRef("");

//   const handleTagNote = (text) => {
//     let string = `${noteFood}${noteFood.length > 0 ? " " : ""}${text}`;
//     if (string.length > 50) setNoteFood(string.slice(0, 50));
//     else setNoteFood(string);
//     noteRef.current.focus();
//   };

//   const handleChangeNote = (e) => {
//     if (e.target.value.length > 50)
//       setNoteFood(removeEmojis(e.target.value.slice(0, 50)));
//     else setNoteFood(removeEmojis(e.target.value));
//   };

//   const handleIncrement = () => {
//     if (quantityFood < 999)
//       setQuantityFood(
//         data.is_sell_by_weight === 1
//           ? takeDecimalNumber((quantityFood * 100 + 1) / 100, 2)
//           : quantityFood + 1
//       );
//     if (data.is_allow_print_stamp === 1 && data.is_sell_by_weight === 0)
//       setFoodOption(
//         foodOption.map((obj) => ({ ...obj, quantity: quantityFood + 1 }))
//       );
//   };

//   const handleDecrement = () => {
//     if (data.is_sell_by_weight === 1 && quantityFood <= 0) return;
//     else if (data.is_sell_by_weight === 0 && quantityFood <= 1) return;

//     setQuantityFood(
//       data.is_sell_by_weight === 1
//         ? takeDecimalNumber((quantityFood * 100 - 1) / 100, 2)
//         : quantityFood - 1
//     );
//     if (data.is_allow_print_stamp === 1 && data.is_sell_by_weight === 0)
//       setFoodOption(
//         foodOption.map((obj) => ({ ...obj, quantity: quantityFood - 1 }))
//       );
//   };

//   const handleChangeInputQuantity = (event) => {
//     let regex = /^-?\d+(\.\d{1,2})?$/;
//     let input = event.target.value;
//     let endValue = 0;

//     if (regex.test(input)) {
//       if (input.toString() === "0.0") {
//         setQuantityFood(input);
//         endValue = input;
//       } else {
//         const value = Math.max(0, Math.min(999, input));
//         setQuantityFood(value);
//         endValue = value;
//       }
//     } else if (
//       input.split("").pop() === "." &&
//       input.split(".").length === 2 &&
//       data.is_sell_by_weight === 1
//     ) {
//       setQuantityFood(input);
//       endValue = input;
//     } else if (input === "") {
//       setQuantityFood(0);
//       endValue = 0;
//     }
//     if (data.is_allow_print_stamp === 1 && data.is_sell_by_weight === 0)
//       setFoodOption(foodOption.map((obj) => ({ ...obj, quantity: endValue })));
//   };

//   const handleFoodToCart = async () => {
//     const minQuantity = data.is_sell_by_weight ? 0.01 : 1;

//     if (quantityFood < minQuantity) {
//       alertNotify.push(`Số lượng tối thiểu là ${minQuantity}`, "Đóng");
//       return;
//     }

//     const dataFood = {
//       ...data,
//       quantity: quantityFood,
//       option: [...foodOption],
//       total: totalFood,
//       note: noteFood,
//     };
//     if (type === 0) {
//       await addFood(dataFood);
//       toast.success("Đã thêm vào giỏ hàng!");
//     } else {
//       await editFood(dataFood);
//     }

//     handleResetModal();
//   };

//   const handleResetModal = () => {
//     if (type === 0) {
//       if (data.is_sell_by_weight === 1) setQuantityFood(0.01);
//       else setQuantityFood(1);
//       setFoodOption([]);
//       setTotalFood(0);
//       setNoteFood("");
//     }

//     onClose();
//   };

//   useEffect(() => {
//     if (data.quantity) setQuantityFood(data.quantity);
//     else if (data.is_sell_by_weight === 1) setQuantityFood(0.01);
//     else setQuantityFood(1);
//     data.option && data.option.length > 0
//       ? setFoodOption(data.option)
//       : setFoodOption([]);
//     data.note ? setNoteFood(data.note) : setNoteFood("");
//   }, [data]);

//   useEffect(() => {
//     let total = foodOption.reduce(
//       (pre, curr) => (pre += curr.price * curr.quantity),
//       0
//     );
//     setTotalFood(total + data.price * quantityFood);
//   }, [foodOption, data, quantityFood]);

//   return (
//     <div id="food-modal">
//       <TabModal active={active} onClose={handleResetModal}>
//         <TabModalHeader title={data.name} onClose={handleResetModal}>
//           <div className="flex flex-col items-end">
//             <span className="text-lg font-bold text-orange-brand-900 leading-6">
//               {formatNumber(data.price || 0)}
//             </span>
//             {/* {data.sale_price !== 0 && (
//                               <div className="flex-1 space-x-[5px] flex items-center">
//                                   <span className="text-xs tracking-[0.25px] text-gray-600 line-through leading-6">{formatNumber(data.price || 0)}</span>
//                                   <span className="leading-6">
//                                       <img src={images.tagIcon} alt="" />
//                                   </span>
//                               </div>
//                           )} */}
//           </div>
//         </TabModalHeader>
//         <div
//           id="food-modal-scroll"
//           className="h-[calc(100vh-114px-90px)] pb-32 overflow-y-auto bg-gray-100 space-y-2 tracking-[0.25px]"
//         >
//           <div className="bg-gray-000">
//             <Card className="!space-y-2">
//               <CardTitle>
//                 <div className="flex justify-between items-center">
//                   <h2 className="text-base font-medium">Ghi chú thêm</h2>
//                 </div>
//               </CardTitle>
//               <CardBody>
//                 <div>
//                   <textarea
//                     ref={noteRef}
//                     className="w-full border border-gray-400 rounded-md py-1 px-2 outline-none"
//                     rows="5"
//                     placeholder="Ghi chú thêm"
//                     onChange={(e) => handleChangeNote(e)}
//                     value={noteFood}
//                   ></textarea>
//                 </div>
//                 <div className="flex flex-wrap gap-x-1 gap-y-2 ">
//                   {data.food_notes &&
//                     data.food_notes.length > 0 &&
//                     data.food_notes.map((note, index) => (
//                       <Tag key={index} onClick={() => handleTagNote(note.note)}>
//                         # {note.note}
//                       </Tag>
//                     ))}
//                 </div>
//               </CardBody>
//             </Card>
//           </div>
//           {data.addition_foods && data.addition_foods.length > 0 && (
//             <FoodOption
//               quantityFood={quantityFood}
//               food={data}
//               data={data.addition_foods}
//               foodOption={foodOption}
//               setFoodOption={setFoodOption}
//             />
//           )}
//           {data.food_in_combo && data.food_in_combo.length > 0 && (
//             <FoodCombo data={data.food_in_combo} />
//           )}
//           <div className="bg-gray-000 p-5">
//             <div className="flex justify-center items-center gap-2">
//               {data.category_type !== 5 && (
//                 <button
//                   onClick={handleDecrement}
//                   className="cursor-pointer w-[30px] h-[30px] inline-flex items-center justify-center rounded-md border border-gray-000 bg-orange-brand-300"
//                 >
//                   <i className="fi-sr-minus text-orange-brand-900"></i>
//                 </button>
//               )}

//               <input
//                 type="text"
//                 disabled={data.category_type === 5}
//                 className="font-bold text-lg w-20 text-center bg-gray-200 rounded-md py-[1px]"
//                 value={quantityFood}
//                 onChange={handleChangeInputQuantity}
//               />

//               {data.category_type !== 5 && (
//                 <button
//                   onClick={handleIncrement}
//                   className="cursor-pointer w-[30px] h-[30px] inline-flex items-center justify-center rounded-md border border-gray-000 bg-orange-brand-300"
//                 >
//                   <i className="fi-sr-plus text-orange-brand-900"></i>
//                 </button>
//               )}
//             </div>
//           </div>
//         </div>
//         <TabBar>
//           <Button onClick={handleFoodToCart}>
//             <div className="text-green-brand-600 text-base uppercase font-bold">
//               {type === 0 ? "Thêm vào giỏ hàng" : "Cập nhật món"}
//             </div>
//             <span className="text-green-brand-600 text-xl font-bold">
//               {formatNumber(totalFood)}
//             </span>
//           </Button>
//         </TabBar>
//       </TabModal>
//     </div>
//   );
// };

// After Change
const FoodOptionModal = ({ active, data, onClose, type = 0 }) => {
  const { addFood, editFood } = useContext(CartContext);
  const [quantityFood, setQuantityFood] = useState(1);
  const [totalFood, setTotalFood] = useState(0);
  const [foodOption, setFoodOption] = useState([]);
  const [noteFood, setNoteFood] = useState("");
  const maxChars = 50;

  const noteRef = useRef("");

  const handleTagNote = (text) => {
    let string = `${noteFood}${noteFood.length > 0 ? " " : ""}${text}`;
    if (string.length > 50) setNoteFood(string.slice(0, 50));
    else setNoteFood(string);
    noteRef.current.focus();
  };

  const handleChangeNote = (e) => {
    if (e.target.value.length > maxChars)
      setNoteFood(removeEmojis(e.target.value.slice(0, maxChars)));
    else setNoteFood(removeEmojis(e.target.value));
  };

  const handleIncrement = () => {
    if (quantityFood < 999)
      setQuantityFood(
        data.is_sell_by_weight === 1
          ? takeDecimalNumber((quantityFood * 100 + 1) / 100, 2)
          : quantityFood + 1
      );
    if (data.is_allow_print_stamp === 1 && data.is_sell_by_weight === 0)
      setFoodOption(
        foodOption.map((obj) => ({ ...obj, quantity: quantityFood + 1 }))
      );
  };

  const handleDecrement = () => {
    if (data.is_sell_by_weight === 1 && quantityFood <= 0) return;
    else if (data.is_sell_by_weight === 0 && quantityFood <= 1) return;

    setQuantityFood(
      data.is_sell_by_weight === 1
        ? takeDecimalNumber((quantityFood * 100 - 1) / 100, 2)
        : quantityFood - 1
    );
    if (data.is_allow_print_stamp === 1 && data.is_sell_by_weight === 0)
      setFoodOption(
        foodOption.map((obj) => ({ ...obj, quantity: quantityFood - 1 }))
      );
  };

  const handleChangeInputQuantity = (event) => {
    let regex = /^-?\d+(\.\d{1,2})?$/;
    let input = event.target.value;
    let endValue = 0;

    if (regex.test(input)) {
      if (input.toString() === "0.0") {
        setQuantityFood(input);
        endValue = input;
      } else {
        const value = Math.max(0, Math.min(999, input));
        setQuantityFood(value);
        endValue = value;
      }
    } else if (
      input.split("").pop() === "." &&
      input.split(".").length === 2 &&
      data.is_sell_by_weight === 1
    ) {
      setQuantityFood(input);
      endValue = input;
    } else if (input === "") {
      setQuantityFood(0);
      endValue = 0;
    }
    if (data.is_allow_print_stamp === 1 && data.is_sell_by_weight === 0)
      setFoodOption(foodOption.map((obj) => ({ ...obj, quantity: endValue })));
  };

  const handleFoodToCart = async () => {
    const minQuantity = data.is_sell_by_weight ? 0.01 : 1;

    if (quantityFood < minQuantity) {
      alertNotify.push(`Số lượng tối thiểu là ${minQuantity}`, "Đóng");
      return;
    }

    const dataFood = {
      ...data,
      quantity: quantityFood,
      option: [...foodOption],
      total: totalFood,
      note: noteFood,
    };
    if (type === 0) {
      await addFood(dataFood);
      toast.success("Đã thêm vào giỏ hàng!");
    } else {
      await editFood(dataFood);
    }

    handleResetModal();
  };

  const handleResetModal = () => {
    if (type === 0) {
      if (data.is_sell_by_weight === 1) setQuantityFood(0.01);
      else setQuantityFood(1);
      setFoodOption([]);
      setTotalFood(0);
      setNoteFood("");
    }

    onClose();
  };

  useEffect(() => {
    if (data.quantity) setQuantityFood(data.quantity);
    else if (data.is_sell_by_weight === 1) setQuantityFood(0.01);
    else setQuantityFood(1);
    data.option && data.option.length > 0
      ? setFoodOption(data.option)
      : setFoodOption([]);
    data.note ? setNoteFood(data.note) : setNoteFood("");
  }, [data]);

  useEffect(() => {
    let total = foodOption.reduce(
      (pre, curr) => (pre += curr.price * curr.quantity),
      0
    );
    setTotalFood(total + data.price * quantityFood);
  }, [foodOption, data, quantityFood]);

  return (
    <div id="food-modal">
      <TabModal active={active} onClose={handleResetModal}>
        <TabModalHeader title={""} onClose={handleResetModal}>
          <div className="flex items-center px-4">
            <div
              className={`relative rounded-md overflow-hidden w-20 h-20 mr-4`}
            >
              <img
                src={URL_ADS_MEDIA + data.avatar}
                alt=""
                className="w-full h-full object-cover"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = images.foodError;
                }}
              />
            </div>
            <div className="flex flex-col flex-1 gap-2">
              <span className="text-lg font-medium text-[#001416] overflow-hidden text-ellipsis whitespace-nowrap">
                {data.name}
              </span>
              <span className="text-lg font-bold text-orange-brand-900 leading-6">
                {formatNumber(data.price || 0)}
              </span>
            </div>
          </div>
        </TabModalHeader>
        <div
          id="food-modal-scroll"
          className="h-[calc(100vh-114px-150px)] pb-32 overflow-y-auto space-y-2 tracking-[0.25px]"
        >
          {data.addition_foods && data.addition_foods.length > 0 && (
            <FoodOption
              quantityFood={quantityFood}
              food={data}
              data={data.addition_foods}
              foodOption={foodOption}
              setFoodOption={setFoodOption}
            />
          )}

          {data.food_in_combo && data.food_in_combo.length > 0 && (
            <FoodCombo data={data.food_in_combo} />
          )}

          <div className="bg-[#FCFBFA] px-4 mt-4">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-base font-medium">Yêu cầu khác</h2>
              <div className="text-right text-[#6B7280] text-sm mt-1">
                {noteFood.length}/{maxChars}
              </div>
            </div>
            <div>
              <textarea
                ref={noteRef}
                className="w-full border border-gray-400 rounded-md py-1 px-2 outline-none"
                rows="5"
                placeholder="Nhập nội dung"
                onChange={(e) => handleChangeNote(e)}
                value={noteFood}
              ></textarea>
            </div>
            <div className="flex flex-wrap gap-x-1 gap-y-2 ">
              {data.food_notes &&
                data.food_notes.length > 0 &&
                data.food_notes.map((note, index) => (
                  <Tag key={index} onClick={() => handleTagNote(note.note)}>
                    # {note.note}
                  </Tag>
                ))}
            </div>
          </div>
        </div>

        <TabBar height="h-[150px]">
          <div className="flex flex-col items-center gap-2">
            <div className="w-full flex justify-between items-center px-4">
              <span className="text-[#374151] font-semibold text-[18px]">
                Tổng tiền:
              </span>
              <span className="text-orange-brand-900 text-xl font-bold">
                {formatNumber(totalFood)}
              </span>
            </div>

            <div className="w-full flex items-center justify-between gap-6 mt-4 px-4">
              <div className="flex justify-center items-center">
                {data.category_type !== 5 && (
                  <button
                    onClick={handleDecrement}
                    className="cursor-pointer w-[30px] h-[30px] inline-flex items-center justify-center border-[#DFE4EA] border-[1px] bg-white"
                  >
                    <i className="fi-sr-minus"></i>
                  </button>
                )}

                <input
                  type="text"
                  disabled={data.category_type === 5}
                  className="text-lg w-14 text-center border-[#DFE4EA] border-[1px] rounded-none border-l-0 border-r-0"
                  value={quantityFood}
                  onChange={handleChangeInputQuantity}
                />

                {data.category_type !== 5 && (
                  <button
                    onClick={handleIncrement}
                    className="cursor-pointer w-[30px] h-[30px] inline-flex items-center justify-center border-[#DFE4EA] border-[1px] bg-white"
                  >
                    <i className="fi-sr-plus"></i>
                  </button>
                )}
              </div>
              
              <Button
                onClick={handleFoodToCart}
                className={"w-[200px] md:w-[300px] bg-custom-gradient hover:bg-custom-gradient-hover"}
              >
                <div className="text-white md:text-base uppercase font-bold text-center w-full">
                  {type === 0 ? "Thêm vào giỏ hàng" : "Cập nhật món"}
                </div>
              </Button>
            </div>
          </div>
        </TabBar>
      </TabModal>
    </div>
  );
};

// Before Change
// const FoodOption = ({
//   data,
//   foodOption,
//   setFoodOption,
//   food,
//   quantityFood,
// }) => {
//   return (
//     <div className="bg-gray-000">
//       <Card className="!space-y-2">
//         <CardTitle>
//           <div className="flex justify-between items-center">
//             <h2 className="text-base font-medium">
//               Món bán kèm&nbsp;
//               <span className="text-sm font-normal text-gray-600">
//                 Có thể chọn nhiều
//               </span>
//             </h2>
//           </div>
//         </CardTitle>
//         <CardBody>
//           <ul>
//             {data.map((option, index) => (
//               <RadioOption
//                 quantityFood={quantityFood}
//                 food={food}
//                 key={index}
//                 borderLine={index !== 0 && true}
//                 data={option}
//                 type={"checkbox"}
//                 namespace={data.id}
//                 foodOption={foodOption}
//                 setFoodOption={setFoodOption}
//               />
//             ))}
//           </ul>
//         </CardBody>
//       </Card>
//     </div>
//   );
// };

// After Change
const FoodOption = ({
  data,
  foodOption,
  setFoodOption,
  food,
  quantityFood,
}) => {
  return (
    <div className="bg-[#FCFBFA] px-4 mt-4">
      <div className="flex justify-between items-center">
        <h2 className="text-base font-medium">
          Món bán kèm&nbsp;
          <span className="text-sm font-normal text-gray-600">
            Có thể chọn nhiều
          </span>
        </h2>
      </div>

      <ul>
        {data.map((option, index) => (
          <RadioOption
            quantityFood={quantityFood}
            food={food}
            key={index}
            borderLine={index !== 0 && true}
            data={option}
            type={"checkbox"}
            namespace={data.id}
            foodOption={foodOption}
            setFoodOption={setFoodOption}
          />
        ))}
      </ul>
    </div>
  );
};

// Before Change
// const FoodCombo = ({ data }) => {
//   return (
//     <div className="bg-[#FCFBFA]">
//       <Card className="!space-y-2">
//         <CardTitle>
//           <div className="flex justify-between items-center">
//             <h2 className="text-base font-medium">Món thuộc combo&nbsp;</h2>
//           </div>
//         </CardTitle>
//         <CardBody>
//           <ul>
//             {data.map((option, index) => (
//               <li
//                 key={index}
//                 className={`pl-[2px] ${
//                   index !== 0 && "border-t border-gray-300"
//                 }`}
//               >
//                 <label
//                   htmlFor={option.id}
//                   className="flex justify-between items-start py-[10px]"
//                 >
//                   <div className="flex items-start gap-2">
//                     <span className="w-7 h-7 inline-flex items-center justify-center rounded-md border border-gray-000 bg-orange-brand-300">
//                       <span className="text-orange-brand-900 text-[10px] font-medium">
//                         {option.combo_quantity}x
//                       </span>
//                     </span>
//                     <div className="flex flex-col flex-1">
//                       <span style={{ overflowWrap: "anywhere" }}>
//                         {option.name}
//                       </span>
//                       {/* <span className="text-orange-brand-900">{formatNumber(option.price)}</span> */}
//                     </div>
//                   </div>
//                 </label>
//               </li>
//             ))}
//           </ul>
//         </CardBody>
//       </Card>
//     </div>
//   );
// };

// After Change
const FoodCombo = ({ data }) => {
  return (
    <div className="bg-[#FCFBFA] px-4 mt-4">
      <div className="flex justify-between items-center">
        <h2 className="text-base font-medium">Món thuộc combo&nbsp;</h2>
      </div>

      <ul>
        {data.map((option, index) => (
          <li
            key={index}
            className={`pl-[2px] ${index !== 0 && "border-t border-gray-300"}`}
          >
            <label
              htmlFor={option.id}
              className="flex justify-between items-start py-[10px]"
            >
              <div className="flex items-center gap-2">
                <span className="w-7 h-7 inline-flex items-center justify-center rounded-md border border-gray-000 bg-orange-brand-300">
                  <span className="text-orange-brand-900 text-[10px] font-medium">
                    {option.combo_quantity}x
                  </span>
                </span>
                <div className="flex flex-col flex-1">
                  <span style={{ overflowWrap: "anywhere" }}>
                    {option.name}
                  </span>
                  {/* <span className="text-orange-brand-900">{formatNumber(option.price)}</span> */}
                </div>
              </div>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

const RadioOption = ({
  food,
  quantityFood,
  borderLine = false,
  data,
  foodOption,
  setFoodOption,
  type,
  namespace,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const handleAddFoodOption = (r) => {
    let type = r.target.type,
      filteredArray = [];
    let idOption = Number(r.target.name);
    let id = Number(r.target.dataset.id);

    if (type === "radio") {
      filteredArray = foodOption.filter((item) => item.idOption !== idOption);
    } else {
      filteredArray = foodOption.filter((item) => item.id !== id);
    }

    if (r.target.checked) {
      setFoodOption([
        ...filteredArray,
        {
          id: id,
          name: r.target.dataset.name,
          price: Number(r.target.dataset.price),
          idOption: idOption,
          quantity:
            food.is_allow_print_stamp === 1 && food.is_sell_by_weight === 0
              ? quantityFood
              : 1,
        },
      ]);
      setIsChecked(true);
    } else {
      setFoodOption([...filteredArray]);
      setIsChecked(false);
      setQuantity(1);
    }
  };

  const handleLabelFoodOption = (r) => {
    if (isChecked) {
      r.preventDefault();
      if (quantity === 999 || food.is_allow_print_stamp === 1) return;
      let foodsStateEdit = [...foodOption];
      let findFood = foodsStateEdit.find((item) => item.id === data.id);
      findFood.quantity = quantity + 1;
      setFoodOption(foodsStateEdit);
    }
  };

  const handleIncrement = (r) => {
    r.stopPropagation();
    if (quantity === 999) return;
    let foodsStateEdit = [...foodOption];
    let findFood = foodsStateEdit.find((item) => item.id === data.id);
    findFood.quantity = quantity + 1;
    setFoodOption(foodsStateEdit);
  };

  const handleDecrement = (r) => {
    r.stopPropagation();
    if (quantity === 1) return;
    let foodsStateEdit = [...foodOption];
    let findFood = foodsStateEdit.find((item) => item.id === data.id);
    findFood.quantity = quantity - 1;
    setFoodOption(foodsStateEdit);
  };

  useEffect(() => {
    if (foodOption.length > 0) {
      let findFood = foodOption.find((item) => item.id === data.id);
      if (findFood) {
        setIsChecked(true);
        setQuantity(findFood.quantity);
      } else {
        setIsChecked(false);
        setQuantity(1);
      }
    } else {
      setIsChecked(false);
      setQuantity(1);
    }
  }, [foodOption, data]);

  return (
    <li className={`pl-[2px] ${borderLine && "border-t border-gray-300"}`}>
      <label
        className="flex justify-between items-start py-[10px]"
        onClick={handleLabelFoodOption}
      >
        <div className="flex items-start gap-2">
          <input
            type={type}
            id={data.id}
            name={namespace}
            className="w-5 h-5"
            data-id={data.id}
            data-price={data.price}
            data-name={data.name}
            onChange={handleAddFoodOption}
            onClick={(r) => r.stopPropagation()}
            checked={isChecked}
          />
          <div className="flex flex-col flex-1">
            <span style={{ overflowWrap: "anywhere" }}>{data.name}</span>
            <span className="text-orange-brand-900">
              {"+ " + formatNumber(data.price)}
            </span>
          </div>
        </div>
        <div>
          {isChecked && (
            <div className="flex justify-center items-center gap-3">
              {food.is_allow_print_stamp === 1 ? (
                <span>{quantity}</span>
              ) : (
                <>
                  <button
                    onClick={handleDecrement}
                    className="cursor-pointer w-6 h-6 inline-flex items-center justify-center rounded-md border border-gray-000 bg-gray-200"
                  >
                    <i className="fi-sr-minus text-gray-600 text-xs"></i>
                  </button>
                  <span>{quantity}</span>
                  <button
                    onClick={handleIncrement}
                    className="cursor-pointer w-6 h-6 inline-flex items-center justify-center rounded-md border border-gray-000 bg-gray-200"
                  >
                    <i className="fi-sr-plus text-gray-600 text-xs"></i>
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </label>
    </li>
  );
};

export { FoodOptionModal };
export default Food;
