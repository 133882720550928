import { RESTAURANT_ACTIVE_STORAGE_KEY, RESTAURANT_STORAGE_KEY, SET_LOCATION, SET_MODAL_BRAND, SET_RESTAURANT, SET_RESTAURANT_ACTIVE } from '../contexts/constains';
import storage from '../utils/storage';

export const menuReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_RESTAURANT:
            storage.set(RESTAURANT_STORAGE_KEY, payload);
            return {
                ...state,
                restaurant: payload,
            };
        case SET_RESTAURANT_ACTIVE:
            storage.set(RESTAURANT_ACTIVE_STORAGE_KEY, payload);
            return {
                ...state,
                restaurant_active: payload,
            };
        case SET_MODAL_BRAND:
            return {
                ...state,
                modal_brand: payload,
            };
        case SET_LOCATION:
            return {
                ...state,
                location: payload,
            };
        default:
            return state;
    }
};
