import { useContext, useEffect, useState } from 'react';

import images from '../../assets/images';

import Button from '../Button';
import Card, { CardBody, CardTitle } from '../Card';
import TabBar from '../TabBar';
import TabModal, { TabModalHeader } from '../TabModal';
import { FoodOptionModal } from '../Food';

import { CartContext } from '../../contexts/CartContext';

import { formatNumber } from '../../utils/currency';
import * as alertNotify from '../../utils/alertNotify';
import Checkout from '../Checkout/Checkout';
import FoodInCart from './FoodInCart';
import {  FOOD_MODAL_UPDATE_LOCATION, PAGE_CHECKOUT_LOCATION, PAGE_INFO_LOCATION } from '../../contexts/constains';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuContext } from '../../contexts/MenuContext';
import { lockScroll } from '../../utils/scrollBody';

// Before Change
// const Cart = ({ active, onClose }) => {
//     const { hash } = useLocation();
//     const navigate = useNavigate();

//     const {
//         cartState: { carts, total, infoCustomer },
//         removeFood,
//         clearFood,
//     } = useContext(CartContext);

//     const {
//         menuState: { restaurant },
//     } = useContext(MenuContext);

//     let [foodOptionUpdateModalActive, setFoodOptionUpdateModalActive] = useState({ active: false, data: {} });
//     let [checkoutModal, setCheckoutModal] = useState(false);

//     const handleOpenFoodUpdateDetail = (active, food) => {
//         setFoodOptionUpdateModalActive({
//             ...foodOptionUpdateModalActive,
//             active,
//             data: food,
//         });
//         window.location.hash = FOOD_MODAL_UPDATE_LOCATION;
//     };

//     const handleRemoveFoodInCart = (id) => {
//         alertNotify.push('Bạn có muốn xóa lựa chọn này ra khỏi giỏ hàng ?', 'Đóng', 'Đồng ý', () => removeFood(id));
//     };

//     const handleClearCart = () => {
//         alertNotify.push(`Bạn có muốn bỏ ${carts.length} món ăn này ra khỏi giỏ hàng ?`, 'Đóng', 'Đồng ý', () => clearFood());
//     };

//     const handleSubmitOrder = async () => {
//         if (carts.length === 0) {
//             alertNotify.push('Giỏ hàng của bạn đang trống. Vui lòng chọn món và thử lại !', 'Đóng');
//             return;
//         }

//         // onClose();
//         setCheckoutModal(true);
//         infoCustomer.isUpdate === 0 ? (window.location.hash = PAGE_INFO_LOCATION) : (window.location.hash = PAGE_CHECKOUT_LOCATION);
//     };

//     const handleCloseFoodUpdateModal = () => {
//         navigate(-1);
//     };

//     useEffect(() => {
//         if (hash !== `#${FOOD_MODAL_UPDATE_LOCATION}` && foodOptionUpdateModalActive.active) {
//             setFoodOptionUpdateModalActive({ ...foodOptionUpdateModalActive, active: false });
//         } else if (hash === `#${FOOD_MODAL_UPDATE_LOCATION}` && !foodOptionUpdateModalActive.active) {
//             Object.keys(foodOptionUpdateModalActive?.data).length > 0 ? setFoodOptionUpdateModalActive({ ...foodOptionUpdateModalActive, active: true }) : navigate(`/?qr_code=${restaurant.qr_code}`);
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [hash]);

//     useEffect(() => {
//         if (foodOptionUpdateModalActive.active) lockScroll(FOOD_MODAL_UPDATE_LOCATION);
//     }, [foodOptionUpdateModalActive.active]);

//     return (
//         <>
//             <div id="cart-modal">
//                 <TabModal active={active} onClose={onClose}>
//                     <TabModalHeader title={`Giỏ hàng`} onClose={onClose}></TabModalHeader>
//                     <div id="cart-modal-scroll" className="h-[calc(100vh-114px-90px)] pb-14 overflow-y-auto bg-gray-100">
//                         <div className="bg-gray-000">
//                             <Card>
//                                 <CardTitle>
//                                     <div className="flex justify-between items-center">
//                                         <h2 className="text-base font-medium">Tóm tắt đơn hàng</h2>
//                                         <div>
//                                             {carts.length > 0 && (
//                                                 <span className="cursor-pointer text-xs font-medium tracking-[0.4px] text-red-brand-500 uppercase" onClick={handleClearCart}>
//                                                     Xoá tất cả
//                                                 </span>
//                                             )}
//                                         </div>
//                                     </div>
//                                 </CardTitle>
//                                 <CardBody>
//                                     {carts.length <= 0 && (
//                                         <div className="w-full text-center">
//                                             <img src={images.emptyCartImg} alt="" className="w-70 h-70 md:w-60 md:h-60 object-cover mx-auto" />
//                                         </div>
//                                     )}
//                                     {carts.length > 0 &&
//                                         carts.map((food, index) => (
//                                             <FoodInCart
//                                                 key={index}
//                                                 data={food}
//                                                 onOpenFoodUpdateModal={() => handleOpenFoodUpdateDetail(true, food)}
//                                                 onRemoveFood={() => handleRemoveFoodInCart(food.id)}
//                                             />
//                                         ))}
//                                     <ul className="tracking-[0.25px]">
//                                         <div className="flex justify-between text-base font-medium pt-[10px]">
//                                             <span>Tổng tạm tính</span>
//                                             <span>{formatNumber(total)}</span>
//                                         </div>
//                                     </ul>
//                                 </CardBody>
//                             </Card>
//                         </div>
//                     </div>
//                     <TabBar>
//                         <Button onClick={handleSubmitOrder}>
//                             <div className="text-green-brand-600 text-base uppercase font-bold">Tiến hành đặt hàng</div>
//                             <span className="text-green-brand-600 text-xl font-bold">{formatNumber(total)}</span>
//                         </Button>
//                     </TabBar>
//                 </TabModal>
//             </div>
//             {checkoutModal && <Checkout onClose={() => setCheckoutModal(false)} onOpenFoodUpdateModal={handleOpenFoodUpdateDetail} />}
//             <FoodOptionModal data={foodOptionUpdateModalActive.data} active={foodOptionUpdateModalActive.active} onClose={handleCloseFoodUpdateModal} type={1} />
//         </>
//     );
// };

// After Change
const Cart = ({ active, onClose }) => {
    const { hash } = useLocation();
    const navigate = useNavigate();

    const {
        cartState: { carts, total, infoCustomer },
        removeFood,
        clearFood,
    } = useContext(CartContext);

    const {
        menuState: { restaurant },
    } = useContext(MenuContext);

    let [foodOptionUpdateModalActive, setFoodOptionUpdateModalActive] = useState({ active: false, data: {} });
    let [checkoutModal, setCheckoutModal] = useState(false);

    const handleOpenFoodUpdateDetail = (active, food) => {
        setFoodOptionUpdateModalActive({
            ...foodOptionUpdateModalActive,
            active,
            data: food,
        });
        window.location.hash = FOOD_MODAL_UPDATE_LOCATION;
    };

    const handleRemoveFoodInCart = (id) => {
        alertNotify.push('Bạn có muốn xóa lựa chọn này ra khỏi giỏ hàng ?', 'Đóng', 'Đồng ý', () => removeFood(id));
    };

    const handleClearCart = () => {
        alertNotify.push(`Bạn có muốn bỏ ${carts.length} món ăn này ra khỏi giỏ hàng ?`, 'Đóng', 'Đồng ý', () => clearFood());
    };

    const handleSubmitOrder = async () => {
        if (carts.length === 0) {
            alertNotify.push('Giỏ hàng của bạn đang trống. Vui lòng chọn món và thử lại !', 'Đóng');
            return;
        }

        // onClose();
        setCheckoutModal(true);
        infoCustomer.isUpdate === 0 ? (window.location.hash = PAGE_INFO_LOCATION) : (window.location.hash = PAGE_CHECKOUT_LOCATION);
    };

    const handleCloseFoodUpdateModal = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (hash !== `#${FOOD_MODAL_UPDATE_LOCATION}` && foodOptionUpdateModalActive.active) {
            setFoodOptionUpdateModalActive({ ...foodOptionUpdateModalActive, active: false });
        } else if (hash === `#${FOOD_MODAL_UPDATE_LOCATION}` && !foodOptionUpdateModalActive.active) {
            Object.keys(foodOptionUpdateModalActive?.data).length > 0 ? setFoodOptionUpdateModalActive({ ...foodOptionUpdateModalActive, active: true }) : navigate(`/?qr_code=${restaurant.qr_code}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash]);

    useEffect(() => {
        if (foodOptionUpdateModalActive.active) lockScroll(FOOD_MODAL_UPDATE_LOCATION);
    }, [foodOptionUpdateModalActive.active]);

    return (
        <>
            <div id="cart-modal">
                <TabModal active={active} onClose={onClose}>
                    <TabModalHeader title={`Giỏ hàng của bạn`} onClose={onClose}></TabModalHeader>
                    <div id="cart-modal-scroll" className="h-[calc(100vh-114px-120px)] pb-14 overflow-y-auto bg-[#FCFBFA]">
                        <div className="px-4 mt-4">
                            <div className="flex justify-between items-center">
                                <h2 className="text-base font-medium">Tóm tắt đơn hàng</h2>
                                <div>
                                    {carts.length > 0 && (
                                        <span className="cursor-pointer text-xs font-medium tracking-[0.4px] text-red-brand-500 uppercase" onClick={handleClearCart}>
                                            Xoá tất cả
                                        </span>
                                    )}
                                </div>
                            </div>
                        
                            {carts.length <= 0 && (
                                <div className="w-full text-center">
                                    <img src={images.emptyCartImg} alt="" className="w-70 h-70 md:w-60 md:h-60 object-cover mx-auto" />
                                </div>
                            )}
                            {carts.length > 0 &&
                                carts.map((food, index) => (
                                    <FoodInCart
                                        key={index}
                                        data={food}
                                        onOpenFoodUpdateModal={() => handleOpenFoodUpdateDetail(true, food)}
                                        onRemoveFood={() => handleRemoveFoodInCart(food.id)}
                                    />
                                ))}
                            {/* <ul className="tracking-[0.25px]">
                                <div className="flex justify-between text-base font-medium pt-[10px]">
                                    <span>Tổng tạm tính</span>
                                    <span>{formatNumber(total)}</span>
                                </div>
                            </ul> */}
                        </div>
                    </div>
                    <TabBar height="h-[120px]">
                        <div className="flex flex-col items-center gap-3">
                            <div className="w-full flex justify-between items-center px-4">
                                <span className="text-[#374151] font-semibold text-[18px]">
                                    Tổng thanh toán:
                                </span>
                                <span className="text-orange-brand-900 text-xl font-bold">
                                    {formatNumber(total)}
                                </span>
                            </div>
                            
                            <div className='w-full flex justify-end px-4'>
                                <Button onClick={handleSubmitOrder} className={`bg-custom-gradient hover:bg-custom-gradient-hover`}>
                                    <div className="text-white text-base uppercase font-bold w-full text-center">Thanh toán đơn hàng</div>
                                </Button>
                            </div>
                        </div>
                    </TabBar>
                </TabModal>
            </div>
            {checkoutModal && <Checkout onClose={() => setCheckoutModal(false)} onOpenFoodUpdateModal={handleOpenFoodUpdateDetail} />}
            <FoodOptionModal data={foodOptionUpdateModalActive.data} active={foodOptionUpdateModalActive.active} onClose={handleCloseFoodUpdateModal} type={1} />
        </>
    );
};

export default Cart;
