import images from '../../assets/images';

const Loading = () => {
    return (
        <div id="loading-app" className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900-16 invisible opacity-0 pointer-events-none transition ease-out duration-500">
            <img src={images.loading} alt="" className="w-20 h-20" />
        </div>
    );
};

export default Loading;
