import { useContext, useState } from 'react';
import { MenuContext } from '../../contexts/MenuContext';
import Card, { CardBody, CardTitle } from '../Card';
import PageModal, { PageHeaderModal } from '../PageModal';
import { QRCodeSVG } from 'qrcode.react';
import images from '../../assets/images';
import copy from 'copy-to-clipboard';
import { CartContext } from '../../contexts/CartContext';
import FoodInCart from '../Cart/FoodInCart';
import { formatNumber } from '../../utils/currency';

const Payment = ({ data, active, onClose }) => {
    const {
        cartState: { carts, total },
    } = useContext(CartContext);

    const {
        menuState: { restaurant },
    } = useContext(MenuContext);

    const [copyMessage, setCopyMessage] = useState({ account: false, amount: false, content: false });
    const [detailFoodActive, setDetailFoodActive] = useState(false);

    const handleCopy = (type, content) => {
        copy(content);
        setCopyMessage({ ...copyMessage, [type]: true });
    };

    const handleClose = () => {
        setCopyMessage({ account: false, amount: false, content: false });
        onClose();
    };

    return (
        <PageModal active={active}>
            <PageHeaderModal title={restaurant?.name || ''} onClose={handleClose}></PageHeaderModal>
            <div className="h-screen pb-28 overflow-y-auto bg-gray-100 space-y-2">
                <div className="bg-gray-000">
                    <Card className="!space-y-2">
                        <CardBody>
                            <div className="flex flex-col gap-8">
                                <div className="text-center">
                                    <span>
                                        Mở App Ngân hàng bất kỳ để <b>quét mã VietQR</b> hoặc <b>chuyển khoản</b> chính xác nội dung bên dưới
                                    </span>
                                </div>
                                <div className="flex flex-col items-center gap-2">
                                    <img src={images.vietqrLogo} alt="" className="w-24" />
                                    <div className="p-1 border-2 border-gray-400 rounded-sm">
                                        <QRCodeSVG value={data?.qr_code} width="160px" height="160px" />
                                    </div>
                                    <div className="flex gap-2 items-center justify-center">
                                        <img src={images.napasLogo} alt="" className="h-5" />
                                        <img src={images.mbLogo} alt="" className="h-6" />
                                    </div>
                                </div>
                                <div className="space-y-2">
                                    {/* <div className="flex items-center gap-2">
                                        <img
                                            src="https://thumbs.dreamstime.com/b/vector-graphic-emblem-hexagon-initials-letter-mb-logo-design-template-vector-graphic-initials-letter-mb-logo-design-template-205164832.jpg"
                                            className="w-8 h-8 object-cover"
                                            alt=""
                                        />
                                        <div className="flex flex-col">
                                            <span className="text-gray-600">Ngân hàng</span>
                                            <span className="font-bold uppercase">DOAN THI THU HUONG</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <div className="flex flex-col flex-1">
                                            <span className="text-gray-600">Chủ tài khoản:</span>
                                            <span className="font-bold uppercase">DOAN THI THU HUONG</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <div className="flex flex-col flex-1">
                                            <span className="text-gray-600">Số tài khoản:</span>
                                            <span className="font-bold uppercase">0321578943115122</span>
                                        </div>
                                        <div>
                                            <button className="bg-green-brand-000 rounded-md px-2 py-[1px] cursor-pointer" onClick={() => handleCopy('account', '0321578943115122')}>
                                                {copyMessage.account ? 'Đã sao chép' : 'Sao chép'}
                                            </button>
                                        </div>
                                    </div> */}
                                    <div className="flex items-center gap-2">
                                        <div className="flex flex-col flex-1">
                                            <span className="text-gray-600">Số tiền:</span>
                                            <span className="font-bold uppercase">{formatNumber(total)}</span>
                                        </div>
                                        <div>
                                            <button className="bg-green-brand-000 rounded-md px-2 py-[1px] cursor-pointer" onClick={() => handleCopy('amount', total)}>
                                                {copyMessage.amount ? 'Đã sao chép' : 'Sao chép'}
                                            </button>
                                        </div>
                                    </div>
                                    {/* <div className="flex items-center gap-2">
                                        <div className="flex flex-col flex-1">
                                            <span className="text-gray-600">Nội dung:</span>
                                            <span className="font-bold uppercase">Order Food</span>
                                        </div>
                                        <div>
                                            <button className="bg-green-brand-000 rounded-md px-2 py-[1px] cursor-pointer" onClick={() => handleCopy('content', 'Order Food')}>
                                                {copyMessage.content ? 'Đã sao chép' : 'Sao chép'}
                                            </button>
                                        </div>
                                    </div> */}
                                </div>
                                {/* <div className="text-center">
                                    <span>
                                        Lưu ý: Nhập chính xác số tiền <b>700,000</b> khi chuyển khoản
                                    </span>
                                </div> */}
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="bg-gray-000">
                    <Card>
                        <CardTitle>
                            <div className="flex justify-between items-center">
                                <h2 className="text-base font-medium">Chi tiết đơn hàng</h2>
                                <div>
                                    <span className="cursor-pointer text-xs font-medium tracking-[0.4px] text-blue-brand-700" onClick={() => setDetailFoodActive(!detailFoodActive)}>
                                        {detailFoodActive ? 'Ẩn' : 'Xem'}
                                    </span>
                                </div>
                            </div>
                        </CardTitle>
                        <CardBody>{detailFoodActive && carts.length > 0 && carts.map((food, index) => <FoodInCart key={index} data={food} offAction />)}</CardBody>
                    </Card>
                </div>
            </div>
        </PageModal>
    );
};

export default Payment;
