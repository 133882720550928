import { Link, useNavigate, useParams } from 'react-router-dom';
import HeaderMini from '../../layouts/Header/HeaderMini';
import { useContext, useEffect } from 'react';
import { MenuContext } from '../../contexts/MenuContext';
import Card, { CardBody, CardTitle } from '../../components/Card';
import { API_GET_DETAIL_ORDER, ENUM_METHOD_GET, ENUM_PROJECT_ID_JAVA_ORDER, URL_ADS_MEDIA } from '../../contexts/constains';
import images from '../../assets/images';
import FoodInCart from '../../components/Cart/FoodInCart';
import { formatNumber } from '../../utils/currency';
import * as alertNotify from '../../utils/alertNotify';
import * as axiosTemplate from '../../utils/axiosTemplate';
import { useState } from 'react';
import moment from 'moment';
import { deliveryStatusRender } from '../../utils';
import config from '../../config';

const OrderDetail = () => {
    const navigate = useNavigate();
    const params = useParams();

    const [orderDetail, setOrderDetail] = useState({});

    const {
        menuState: { restaurant },
    } = useContext(MenuContext);

    const handleHomeBack = () => {
        navigate(`/?qr_code=${restaurant.qr_code || ''}`);
    };

    useEffect(() => {
        const fetchGetDetailOrder = async (loading = false) => {
            const project = ENUM_PROJECT_ID_JAVA_ORDER;
            const method = ENUM_METHOD_GET;
            const api = API_GET_DETAIL_ORDER.sprintf(params.id);
            const res = await axiosTemplate.get(project, method, api, { loading });
            switch (res.status) {
                case 200:
                    let foods = res.data.customer_order_details.map((item) => ({
                        id: item.food_id,
                        name: item.food_name,
                        food_in_combo: item.customer_order_detail_combo,
                        option: item.customer_order_detail_addition,
                        note: item.note,
                        total: item.total_price_addition,
                        quantity: item.quantity,
                    }));
                    setOrderDetail({ ...res.data, customer_order_details: foods });
                    break;
                case 500:
                    alertNotify.push('Lỗi dữ liệu', 'Đóng');
                    break;
                default:
                    alertNotify.push(res.message, 'Đóng');
                    break;
            }
        };

        let interval;

        if (params.id) {
            fetchGetDetailOrder(true);
            interval = setInterval(fetchGetDetailOrder, 5000);
        } else {
            alertNotify.push('Hóa đơn không tồn tại vui lòng kiểm tra lại', 'Đóng');
        }

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="pt-12 bg-gray-100 min-h-screen">
            <HeaderMini title="Chi tiết đơn hàng" onClose={() => navigate(-1)} />
            <div className="space-y-2 pb-20">
                <div className="bg-gray-000">
                    <Card className="!space-y-2 !py-2" onClick={handleHomeBack}>
                        <CardBody>
                            <div className="flex items-center">
                                <img
                                    src={URL_ADS_MEDIA + restaurant.logo}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = images.foodError;
                                    }}
                                    className="w-16 h-16 object-cover mr-2 rounded-b10"
                                    alt=""
                                />
                                <div className="flex-1 w-[calc(100%-64px-12px)] space-y-1">
                                    <h1 className="text-base font-bold">{restaurant?.name || ''}</h1>
                                    <div className="flex items-center">
                                        <span className={`font-medium ${deliveryStatusRender(orderDetail.customer_order_status).color}`}>
                                            {deliveryStatusRender(orderDetail.customer_order_status).text}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="bg-gray-000">
                    <Card className="!space-y-2">
                        <CardTitle>
                            <div className="relative flex items-center">
                                <i className="fi-rr-location-alt absolute left-0 text-base text-orange-brand-900"></i>
                                <h2 className="text-base font-medium mx-6">Thông tin nhận hàng</h2>
                            </div>
                        </CardTitle>
                        <CardBody>
                            <div className="relative ml-6">
                                <div className="flex flex-col text text-gray-600 gap-1">
                                    <span style={{ overflowWrap: 'anywhere' }}>
                                        <span>{orderDetail.customer_name}</span>&nbsp;|&nbsp;<span>{orderDetail.phone}</span>
                                    </span>
                                    <span style={{ overflowWrap: 'anywhere' }}>{orderDetail.address}</span>
                                    {orderDetail.note && (
                                        <div className="flex gap-1">
                                            <i className="fi-rr-document text-orange-brand-900 translate-y-[3px]"></i> <span style={{ overflowWrap: 'anywhere' }}>{orderDetail.note}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                {orderDetail.restaurant_third_party_delivery_id !== 0 && (
                    <div className="bg-gray-000">
                        <Card className="!space-y-2">
                            <CardTitle>
                                <div className="relative flex items-center">
                                    <h2 className="text-base font-medium">Thông tin vận chuyển</h2>
                                </div>
                            </CardTitle>
                            <CardBody>
                                <div className="relative">
                                    <div className="grid grid-cols-2 gap-y-1">
                                        <span className="text-gray-600">Thời gian tạo</span>
                                        <span className="font-medium">{orderDetail.created_at && moment(orderDetail.created_at, 'DD/MM/YYYY HH:mm').format('HH:mm DD/MM/YYYY')}</span>
                                        <span className="text-gray-600">Tên tài xế</span>
                                        <span className="font-medium">{orderDetail.shipper_name}</span>
                                        <span className="text-gray-600">SĐT</span>
                                        <span className="font-medium">{orderDetail.shipper_phone}</span>
                                    </div>
                                    <div className="flex w-full justify-between py-4 pb-0 text-gray-600 font-medium">
                                        <a href={'tel:' + orderDetail.shipper_phone} alt="" className="flex-1 text-center border-r border-gray-300">
                                            <div>
                                                <i className="fi-rr-phone-call text-base"></i>
                                                <span>Liên hệ tài xế</span>
                                            </div>
                                        </a>
                                        <Link to={config.routes.tracking + '?url=' + orderDetail.tracking_url} alt="" className="flex-1 text-center">
                                            <div>
                                                <i className="fi-rr-map-marker text-base"></i>
                                                <span>Xem bản đồ</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                )}

                <div className="bg-gray-000">
                    <Card>
                        <CardTitle>
                            <div className="flex justify-between items-center">
                                <h2 className="text-base font-medium">Chi tiết đơn hàng</h2>
                            </div>
                        </CardTitle>
                        <CardBody>
                            {orderDetail?.customer_order_details?.length <= 0 && (
                                <div className="w-full text-center">
                                    <img src={images.emptyCartImg} alt="" className="w-70 h-70 md:w-60 md:h-60 object-cover mx-auto" />
                                </div>
                            )}
                            {orderDetail?.customer_order_details?.length > 0 && orderDetail.customer_order_details.map((food, index) => <FoodInCart key={index} data={food} offAction />)}
                            <ul className="tracking-[0.25px] space-y-2">
                                <div className="flex justify-between">
                                    <span>Tổng tạm tính</span>
                                    <span>{formatNumber(orderDetail.total_amount)}</span>
                                </div>
                                <div className="flex justify-between">
                                    <span>Phí vận chuyển</span>
                                    <span>{orderDetail.shipping_fee === 0 ? 'Miễn phí' : formatNumber(orderDetail.shipping_fee)}</span>
                                </div>
                            </ul>
                        </CardBody>
                    </Card>
                </div>
                <div className="bg-gray-000">
                    <Card>
                        <CardBody>
                            <ul className="tracking-[0.25px] space-y-4">
                                <div className="flex justify-between text-base font-medium">
                                    <span>Tổng thanh toán</span>
                                    <span>{formatNumber(orderDetail.total_amount + orderDetail.shipping_fee)}</span>
                                </div>
                            </ul>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default OrderDetail;
