// Before Change
// const TabModal = ({ active, onClose, children }) => {
//     return (
//         <div>
//             <div className={`fixed inset-0 bg-gray-900-16 z-30 transition ease-out duration-500 ${!active && 'invisible opacity-0 pointer-events-none'} `} onClick={onClose}></div>
//             <div
//                 className={`fixed z-30 bottom-0 left-0 right-0 bg-gray-000 rounded-t-[10px] h-[calc(100%-70px)] transition ease-out duration-300 delay-0 ${
//                     active ? '!translate-y-0' : 'translate-y-full'
//                 }`}
//             >
//                 {children}
//             </div>
//         </div>
//     );
// };

// Before Change
// const TabModalHeader = ({ title, onClose, children }) => {
//     return (
//         <div className="relative w-full shadow-default">
//             <div className="container py-5 mx-auto flex items-start justify-between">
//                 <div className="flex items-center gap-[10px]">
//                     <div className="w-6 h-6 rounded-full flex items-center justify-center bg-gray-200" onClick={onClose}>
//                         <i className="fi-sr-cross w-3 h-3 text-xs text-gray-600"></i>
//                     </div>
//                     <span className="text-lg font-bold leading-6 flex-1" style={{overflowWrap: 'anywhere'}}>{title}</span>
//                 </div>
//                 <div>{children}</div>
//             </div>
//         </div>
//     );
// };

const TabModal = ({ active, onClose, children }) => {
  return (
    <div>
      <div
        className={`fixed inset-0 bg-gray-900-16 z-30 transition ease-out duration-500 ${
          !active && "invisible opacity-0 pointer-events-none"
        } `}
        onClick={onClose}
      ></div>
      <div
        className={`fixed z-30 bottom-0 right-0 bg-[#FCFBFA] h-full w-full md:w-[500px] transition ease-out duration-300 delay-0 ${
          active ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

const TabModalHeader = ({ title, onClose, children }) => {
  return (
    <div className="relative w-full shadow-default">
      <div className="pt-4 flex items-start justify-between">
        <div className="flex items-center gap-[10px] w-full justify-end pr-4">
          <div
            className="w-6 h-6 rounded-full flex items-center justify-center bg-gray-200 cursor-pointer"
            onClick={onClose}
          >
            <i className="fi-sr-cross w-3 h-3 text-xs text-gray-600"></i>
          </div>
        </div>
      </div>

      <div className={`flex items-center gap-[10px] px-4`}>
        <span
          className="text-lg md:text-2xl text-[#000000] font-bold leading-6 flex-1"
          style={{ overflowWrap: "anywhere" }}
        >
          {title}
        </span>
      </div>

      <div className="pb-4">{children}</div>
    </div>
  );
};

export { TabModalHeader };
export default TabModal;
