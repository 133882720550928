import { formatNumber } from '../../utils/currency';

const FoodInCart = ({ data, onOpenFoodUpdateModal, onRemoveFood, offAction = false }) => {
    return (
        <div className="py-[10px] flex justify-between border-b border-gray-300">
            <div className="flex items-start gap-2 flex-1">
                <span className="w-7 h-7 inline-flex items-center justify-center rounded-md border border-gray-000 bg-orange-brand-300">
                    <span className="text-orange-brand-900 text-[10px] font-medium">{data.quantity}x</span>
                </span>
                <div className="flex-1 flex flex-col gap-1 w-[calc(100%-28px)]">
                    <span style={{ overflowWrap: 'anywhere' }}>{data.name}</span>
                    <ul className="flex flex-col text-gray-600 text-xs">
                        {data.food_in_combo && data.food_in_combo.length > 0 && <span className="text-orange-brand-900">[Món thuộc combo]</span>}
                        {data.food_in_combo &&
                            data.food_in_combo.length > 0 &&
                            data.food_in_combo.map((item, index) => (
                                <span key={index} style={{ overflowWrap: 'anywhere' }}>
                                    + {item.name}&nbsp;<span className="text-orange-brand-900">x{item.combo_quantity || item.quantity}</span>
                                </span>
                            ))}
                    </ul>
                    <ul className="flex flex-col text-gray-600 text-xs">
                        {data.option && data.option.length > 0 && <span className="text-orange-brand-900">[Món bán kèm]</span>}
                        {data.option &&
                            data.option.length > 0 &&
                            data.option.map((item, index) => (
                                <span key={index} style={{ overflowWrap: 'anywhere' }}>
                                    + {item.name}&nbsp;<span className="text-orange-brand-900">x{item.quantity}</span>
                                </span>
                            ))}
                    </ul>
                    {data.note !== '' && (
                        <div className="flex text-gray-600 text-xs gap-1">
                            <i className="fi-rr-document text-orange-brand-900 translate-y-[1px]" />
                            <span style={{ overflowWrap: 'anywhere' }}>{data.note}</span>
                        </div>
                    )}
                    {!offAction && (
                        <span className="cursor-pointer text-xs font-medium tracking-[0.4px] uppercase space-x-3">
                            <span className="text-orange-brand-900" onClick={onOpenFoodUpdateModal}>
                                Chỉnh sửa
                            </span>
                            <span className="text-red-brand-500" onClick={onRemoveFood}>
                                Xoá
                            </span>
                        </span>
                    )}
                </div>
            </div>
            <div className="flex flex-col items-end">
                <span>{formatNumber(data.total)}</span>
                {/* {data.sell_price && <span className="text-xs line-through text-gray-600">{formatNumber(data.total - data.sell_price)}</span>} */}
            </div>
        </div>
    );
};

export default FoodInCart;
