export function removeEmojis(text) {
    return text.replace(
        /[\u{1F600}-\u{1F6FF}|[\u{2600}-\u{26FF}|\u{2700}-\u{27BF}|\u{1F300}-\u{1F5FF}|\u{1F900}-\u{1F9FF}|[\u{1F1E0}-\u{1F1FF}|\u{1F7E0}-\u{1F7FF}|\u{1F80}-\u{1FB4}|\u{1FBC}|\u{1FC2}-\u{1FC4}|\u{1FCC}|\u{1FD0}-\u{1FD3}|\u{1FD6}-\u{1FDB}|\u{1FE0}-\u{1FEC}|\u{1FF2}-\u{1FF4}|\u{1FFC}|\u{200D}|\u{2070}-\u{20CF}|\u{2100}-\u{218F}|\u{2460}-\u{24FF}|\u{25A0}-\u{25FF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}|\u{2800}-\u{28FF}|\u{2900}-\u{297F}|\u{2B00}-\u{2BFF}|\u{2C60}-\u{2C7F}|\u{2E00}-\u{2E7F}|\u{A720}-\u{A7FF}|\u{A830}-\u{A83F}|\u{AB00}-\u{AB2F}|\u{FB00}-\u{FB06}|\u{FE00}-\u{FE0F}|\u{FE10}-\u{FE19}|\u{FE30}-\u{FE6F}|\u{FF00}-\u{FF20}|\u{FFF0}-\u{FFFF}|\u{1F000}-\u{1F02F}|\u{1F0A0}-\u{1F0FF}|\u{1F100}-\u{1F64F}|\u{1F680}-\u{1F6FF}|\u{1F910}-\u{1F96B}|\u{1F980}-\u{1F9E0}|\u{1F9F0}-\u{1F9FF}|\u{1FA70}-\u{1FAFF}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FA6F}|\u{1FA70}-\u{1FAFF}|\u{E000}-\u{F8FF}|\u{F0000}-\u{FFFFF}|\u{100000}-\u{10FFFF}]/gu,
        '',
    );
}

export function deliveryStatusRender(status) {
    switch (status) {
        case 0:
        case 4:
            return {
                text: 'Đang xử lý',
                color: 'text-orange-brand-950',
                bg: 'bg-orange-brand-200',
            };
        case 7:
            return {
                text: 'Đang tìm tài xế',
                color: 'text-orange-brand-950',
                bg: 'bg-orange-brand-200',
            };
        case 1:
        case 6:
        case 8:
            return {
                text: 'Đang giao',
                color: 'text-blue-brand-900',
                bg: 'bg-blue-brand-100',
            };
        case 2:
        case 3:
        case 9:
            return {
                text: 'Đã huỷ',
                color: 'text-red-brand-900',
                bg: 'bg-red-brand-100',
            };
        case 10:
            return {
                text: 'Đã giao',
                color: 'text-blue-brand-900',
                bg: 'bg-blue-brand-100',
            };
        default:
            return {
                text: 'Đang xử lý',
                color: 'text-orange-brand-950',
                bg: 'bg-orange-brand-100',
            };
    }
}
