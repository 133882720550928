function removeformatNumber(num) {
    num = String(num);
    return Number(num.replace(/[^0-9.-]+/g, ''));
}

function formatNumber(num) {
    num = takeDecimalNumber(num, 2);
    return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
}

function removeVietNameseDiacritics(str) {
    return str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/đ/g, 'd')
        .replace(/Đ/g, 'd')
        .toLowerCase();
}

function formatKeysearch(searchTerm) {
    let str = searchTerm.toString().replace(/\s+/g, '');
    return removeVietNameseDiacritics(str);
}

function takeDecimalNumber(num, n) {
    //num : số cần xử lý
    //n: số chữ số sau dấu phẩy cần lấy
    let base = 10 ** n;
    let result = Math.round(num * base) / base;
    return result;
}

function generateRandomString(length) {
    return Array.from({ length }, () => String.fromCharCode(Math.floor(Math.random() * 26) + 97)).join('');
}

export { formatNumber, removeformatNumber, removeVietNameseDiacritics, formatKeysearch, takeDecimalNumber, generateRandomString };
