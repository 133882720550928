import { createContext, useReducer } from 'react';

import { menuReducer } from '../reducers/MenuReducer';

import { RESTAURANT_ACTIVE_STORAGE_KEY, RESTAURANT_STORAGE_KEY, SET_LOCATION, SET_MODAL_BRAND, SET_RESTAURANT, SET_RESTAURANT_ACTIVE } from './constains';
import storage from '../utils/storage';

export const MenuContext = createContext();

const MenuProvider = ({ children }) => {
    const [menuState, dispatch] = useReducer(menuReducer, {
        restaurant: storage.get(RESTAURANT_STORAGE_KEY, {}),
        restaurant_active: storage.get(RESTAURANT_ACTIVE_STORAGE_KEY, {}),
        modal_brand: 0,
        location: {
            lat: 0,
            lng: 0,
        },
    });

    // Set Restaurant
    const setRestaurant = async (data) => {
        dispatch({ type: SET_RESTAURANT, payload: data });
        return true;
    };

    // Set Restaurant Active
    const setRestaurantActive = async (data) => {
        dispatch({ type: SET_RESTAURANT_ACTIVE, payload: data });
        return true;
    };

    // Set Restaurant Active
    const setModalBrand = async (value) => {
        dispatch({ type: SET_MODAL_BRAND, payload: value });
        return true;
    };

    // Set Restaurant Active
    const setLocation = async (value) => {
        dispatch({ type: SET_LOCATION, payload: value });
        return true;
    };

    const menuContextData = {
        menuState,
        setRestaurant,
        setRestaurantActive,
        setModalBrand,
        setLocation,
    };

    return <MenuContext.Provider value={menuContextData}>{children}</MenuContext.Provider>;
};

export default MenuProvider;
