import { disableScroll } from './scrollBody';

export const push = (title, textCancel = '', textAccept = '', onAccept) => {
    let alertEl = document.getElementById('alert-notify-app');
    alertEl.querySelector('.alert-overlay').classList.remove('invisible', 'opacity-0', 'pointer-events-none');
    alertEl.querySelector('.alert-popup').classList.remove('scale-0', 'opacity-0');
    alertEl.querySelector('.alert-title').innerText = title;
    if (textCancel !== '') {
        alertEl.querySelector('.alert-cancel').classList.remove('hidden');
        alertEl.querySelector('.alert-cancel').innerText = textCancel;
    } else alertEl.querySelector('.alert-cancel').classList.add('hidden');
    if (textAccept !== '') {
        alertEl.querySelector('.alert-accept').classList.remove('hidden');
        alertEl.querySelector('.alert-accept').innerText = textAccept;
    } else alertEl.querySelector('.alert-accept').classList.add('hidden');
    disableScroll();

    alertEl.querySelector('.alert-accept').onclick = onAccept;
};
