import { ADD_FOOD, CART_STORAGE_KEY, CLEAR_FOOD, EDIT_FOOD, INFO_CUSTOMER_STORAGE_KEY, REMOVE_FOOD, RESET_CUSTOMER, TOTAL_CART_STORAGE_KEY, UPDATE_CUSTOMER } from '../contexts/constains';
import storage from '../utils/storage';

export const cartReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_FOOD:
            let addFoods = [...state.carts, payload];
            let totalAddFood = addFoods.reduce((prev, curr) => (prev += curr.total), 0);
            storage.set(CART_STORAGE_KEY, addFoods);
            storage.set(TOTAL_CART_STORAGE_KEY, totalAddFood);
            return {
                ...state,
                carts: addFoods,
                total: totalAddFood,
            };
        case EDIT_FOOD:
            let foodsStateEdit = [...state.carts];
            let findFood = foodsStateEdit.find((item) => item.id === payload.id);
            foodsStateEdit.splice(foodsStateEdit.indexOf(findFood), 1, payload);

            let totalEditFood = foodsStateEdit.reduce((prev, curr) => (prev += curr.total), 0);

            storage.set(CART_STORAGE_KEY, foodsStateEdit);
            storage.set(TOTAL_CART_STORAGE_KEY, totalEditFood);
            return {
                ...state,
                carts: foodsStateEdit,
                total: totalEditFood,
            };
        case REMOVE_FOOD:
            let removeFoods = state.carts.filter((item) => item.id !== payload);

            let totalRemoveFood = removeFoods.reduce((prev, curr) => (prev += curr.total), 0);

            storage.set(CART_STORAGE_KEY, removeFoods);
            storage.set(TOTAL_CART_STORAGE_KEY, totalRemoveFood);
            return {
                ...state,
                carts: removeFoods,
                total: totalRemoveFood,
            };
        case CLEAR_FOOD:
            storage.set(CART_STORAGE_KEY, []);
            storage.set(TOTAL_CART_STORAGE_KEY, 0);
            return {
                ...state,
                carts: [],
                total: 0,
            };
        case UPDATE_CUSTOMER:
            storage.set(INFO_CUSTOMER_STORAGE_KEY, {
                ...payload,
                isUpdate: 1,
            });

            return {
                ...state,
                infoCustomer: {
                    ...payload,
                    isUpdate: 1,
                },
            };
        case RESET_CUSTOMER:
            storage.set(INFO_CUSTOMER_STORAGE_KEY, {
                name: '',
                phone: '',
                address: {
                    address: '',
                    note: '',
                },
                isUpdate: 0,
            });

            return {
                ...state,
                infoCustomer: {
                    name: '',
                    phone: '',
                    address: {
                        address: '',
                        note: '',
                    },
                    isUpdate: 0,
                },
            };
        default:
            return state;
    }
};
