const HeaderMini = ({ title, subTitle, onClose, children }) => {
    return (
        <div className="w-full shadow-default bg-gray-000 fixed top-0 z-10">
            <div className="container mx-auto pl-0 flex items-start justify-between">
                <div className="flex items-center py-1">
                    <div className="flex p-3 pl-5 items-center justify-center" onClick={onClose}>
                        <i className="fi-rr-angle-left text-base"></i>
                    </div>
                    <span className="flex flex-col leading-3">
                        <span className="text-lg font-bold leading-6">{title}</span>
                        <span>{subTitle}</span>
                    </span>
                </div>
                <div>{children}</div>
            </div>
        </div>
    );
};

export default HeaderMini;
