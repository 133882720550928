document.addEventListener('DOMContentLoaded', function () {
    document.addEventListener('input', function (event) {
        let target = event.target;
        if (target.matches('input[data-max-length]') && (event.inputType === 'insertText' || event.type === 'paste')) {
            validateInputMaxLength(event);
        }

        if (event.target.matches('input[data-phone-hotline]')) {
            event.target.value = event.target.value.replace(/[^0-9]/g, '').slice(0, 11);
        }
        removeErrorInput(event.target);
    });

    document.addEventListener('paste', function (event) {
        if (event.target.matches('input[data-max-length]') && (event.inputType === 'insertText' || event.type === 'paste')) {
            validateInputMaxLength(event);
        }

        if (event.target.matches('input[data-phone-hotline]')) {
            event.target.value = event.target.value.replace(/[^0-9]/g, '').slice(0, 11);
        }

        removeErrorInput(event.target);
    });
});

function validateInputMaxLength(event) {
    let input = event.target;
    let maxLength = parseInt(input.getAttribute('data-max-length'), 10);

    input.value = input.value.replace(/\  /g, '');

    if (input.value.length > maxLength) {
        input.value = input.value.slice(0, maxLength);
    }
}

function checkValidateSave(el) {
    let flag = true;
    let text = '';

    const inputs = document.querySelector(el).querySelectorAll('input:not(:checked)');

    inputs.forEach((input) => {
        if (input.type !== 'file') {
            input.value = input.value.trim();

            if (!input.parentElement.classList.contains('d-none') && !input.classList.contains('disabled')) {
                text = '';
                if (input.getAttribute('data-empty')) {
                    if (input.value === '') {
                        if (input.closest('table')) {
                            input.parentElement.classList.add('border-danger');
                        } else {
                            text = 'Không được để trống';
                        }
                        flag = false;
                    }
                }
                input.value = input.value.trim();

                if (input.getAttribute('data-max-length') !== undefined) {
                    input.value = input.value.trim();
                }

                // Độ tối thiểu chuỗi
                if (input.getAttribute('data-min-length')) {
                    if (input.value.length >= parseInt(input.getAttribute('data-min-length'))) {
                        input.setAttribute('data-check', 0);
                    } else {
                        if (input.value !== '') {
                            if (input.closest('table')) {
                                flag = false;
                                input.parentElement.classList.add('border-danger');
                            } else {
                                text = `Độ dài tối thiểu ${input.getAttribute('data-min-length')} ký tự`;
                                flag = false;
                            }
                        }
                    }
                }

                if (input.getAttribute('data-phone-hotline')) {
                    const rePhone = /^(09|03|07|08|05|04|06|02|01|1).*/;

                    input.value = input.value.replace(/([^0-9\\])[\/]/g, '');

                    if (input.value.length <= 11 && input.value !== '') {
                        if (!(input.value.length < 2 || (input.value.length >= 1 && input.value.substring(0, 2).match(rePhone)))) {
                            if (input.closest('table')) {
                                input.parentElement.classList.add('border-danger');
                            } else {
                                text = 'Đầu SĐT không đúng định dạng';
                                flag = false;
                            }
                        } else {
                            if ((input.value.length === 10 || input.value.length === 11) && input.value.substring(0, 2).match(rePhone)) {
                                input.setAttribute('data-check', 0);
                            } else {
                                if (input.closest('table')) {
                                    input.parentElement.classList.add('border-danger');
                                } else {
                                    text = 'Số điện thoại chưa đúng 10 hoặc 11 số!';
                                    flag = false;
                                }
                            }
                        }
                    } else {
                        if (input.closest('table')) {
                            input.parentElement.classList.add('border-danger');
                        } else {
                            text = 'Không được để trống';
                            flag = false;
                        }
                    }
                }

                if (text !== '') {
                    removeErrorInput(input);
                    addErrorInput(input, text);
                } else {
                    removeErrorInput(input);
                }
            }
        }
    });

    return flag;
}

const addErrorInput = (element, text) => {
    let parent = element.closest('.form-validate');
    if (parent) {
        parent.classList.add('error-validate');
        parent.querySelector('.error-message').innerText = text;
    }
};

const removeErrorInput = (element) => {
    let parent = element.closest('.form-validate');
    if (parent) {
        parent.classList.remove('error-validate');
        parent.querySelector('.error-message').innerText = '';
    }
};

export default checkValidateSave;
