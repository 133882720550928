import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import config from './config';

import Home from './pages/Home';
import Order from './pages/Order';
import OrderDetail from './pages/OrderDetail';
import Tracking from './pages/Tracking';

import Alert from './components/Alert';
import Loading from './components/Loading';

import CartProvider from './contexts/CartContext';
import MenuProvider from './contexts/MenuContext';
import OrderProvider from './contexts/OrderContext';

import './utils/stringExtensions';
import './utils/validate';

function App() {
    return (
        <MenuProvider>
            <CartProvider>
                <OrderProvider>
                    <Router>
                        <div className="app font-roboto text-sm tracking-[0.15px] text-gray-900 ">
                            <Routes>
                                <Route path={config.routes.home} element={<Home />} />
                                <Route path={config.routes.orders} element={<Order />} />
                                <Route path={config.routes.orderDetail} element={<OrderDetail />} />
                                <Route path={config.routes.tracking} element={<Tracking />} />
                            </Routes>
                        </div>
                    </Router>
                    <Toaster position="top-center" />
                    <Alert />
                    <Loading />
                </OrderProvider>
            </CartProvider>
        </MenuProvider>
    );
}

export default App;
