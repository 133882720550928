import { useRef, useState } from "react";
import { FOOD_MODAL_LOCATION } from "../../contexts/constains";
import Food from "../Food/Food";
import Card, { CardBody, CardTitle } from "../Card";
import Slider from "react-slick";
import images from "../../assets/images";

function FoodNew({
  categoryValue,
  foodOptionModalActive,
  setFoodOptionModalActive,
  food,
}) {
  const foodList = food ? food.filter(item => {
    return item.food_addition_ids.length === 0 && item.food_in_combo.length === 0;
  }) : [];

  const handleOpenFoodDetail = (active, food) => {
    setFoodOptionModalActive({
      ...foodOptionModalActive,
      active,
      data: food,
    });
    window.location.hash = FOOD_MODAL_LOCATION;
  };

  const NextArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      className="custom-arrow bg-white shadow-lg rounded-full p-1 md:p-2 text-gray-500 hover:bg-gray-100 absolute -right-2 md:-right-4 top-1/2 transform -translate-y-1/2 z-[5]"
    >
      <img src={images.nextImg} alt="" />
    </button>
  );

  const PrevArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      className="custom-arrow bg-white shadow-lg rounded-full p-1 md:p-2 text-gray-500 hover:bg-gray-100 absolute -left-4 md:-left-6 top-1/2 transform -translate-y-1/2 z-[5]"
    >
      <img src={images.prevImg} alt="" />
    </button>
  );

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
  };

  return foodList && foodList.length > 0 ? (
    categoryValue === 0 ? (
      <div className="relative bg-[#FFF4EE]">
        <Card>
          <CardTitle>
            <h2 className="text-lg font-bold">Món mới</h2>
          </CardTitle>
          <CardBody type="line">
            <Slider {...settings}>
              {foodList && foodList.length > 0
                ? foodList.map((food, index) => (
                    <div className="mr-4" key={index}>
                      <Food
                        isSlider={true}
                        data={food}
                        type="line"
                        onOpenFoodOption={() =>
                          handleOpenFoodDetail(true, food)
                        }
                      />
                    </div>
                  ))
                : null}
            </Slider>
          </CardBody>
        </Card>
      </div>
    ) : (
      <div className="bg-white">
      </div>
    )
  ) : null;
}

export default FoodNew;
