import { useContext } from "react";
import { MenuContext } from "../../contexts/MenuContext";
import { URL_ADS_MEDIA } from "../../contexts/constains";
import images from "../../assets/images";
import config from "../../config";
import { Link } from "react-router-dom";

function Hero() {
  const {
    menuState: { restaurant, restaurant_active },
    setModalBrand,
  } = useContext(MenuContext);
  return (
    <>
      <div className="relative">
        <div className="w-full h-auto">
          <img
            src={URL_ADS_MEDIA + restaurant.banner}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = images.bgEmpty;
            }}
            className="aspect-[2/1] object-cover bg-gray-600 w-full"
            alt=""
          />
        </div>

        <div className="absolute -bottom-16 md:-bottom-14 right-0 left-0 z-10">
          <div className="container mx-auto">
            <div className="bg-gray-000 rounded-2xl p-[20px] shadow-cart space-y-3">
              <div className="flex items-start md:items-center gap-2 md:gap-4 justify-between flex-wrap">
                <h1 className="overflow-hidden text-ellipsis text-xl md:text-2xl font-bold">
                  {restaurant?.name || ""}
                </h1>
                <Link to={config.routes.orders} className="flex items-center gap-1 h-8 px-2 rounded-lg border border-gray-300">
                    <i className="fi-rr-time-fast text-lg flex"></i>
                    <span>Lịch sử đơn hàng</span>
                </Link>
              </div>

              <div
                className="flex items-center"
                onClick={() => setModalBrand(1)}
              >
                <span className="mr-2 whitespace-nowrap flex text-blue-brand-700">
                  <i
                    className="fi-rr-bank icon-brand text-2xl"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="Thương hiệu"
                  ></i>
                </span>
                <span className="overflow-hidden text-ellipsis whitespace-nowrap flex-1 text-[#374151] font-medium text-base">
                  {restaurant_active.name}
                </span>
                <span className="ml-1 whitespace-nowrap">
                  <i className="fi-rr-angle-small-right text-gray-600"></i>
                </span>
              </div>

              <div
                className="flex items-center"
                onClick={() => setModalBrand(2)}
              >
                <span className="mr-2 whitespace-nowrap flex text-green-brand-600">
                  <i
                    className="fi-rr-marker icon-branch text-2xl text-orange-brand-900"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="Chi nhánh"
                  ></i>
                </span>

                {restaurant.lat !== 0 && restaurant.lng !== 0 && (
                  <span className="mr-2 whitespace-nowrap bg-[#CBF2D7] text-[#31A655] px-3 py-1 rounded-[20px] font-medium">
                    {restaurant_active.branches &&
                      restaurant_active.branches.length > 0 &&
                      Math.round(
                        restaurant_active.branches[0].distance_km * 100
                      ) /
                        100 +
                        " km"}
                  </span>
                )}

                <span className="overflow-hidden text-ellipsis whitespace-nowrap flex-1 text-[#374151] font-medium text-base">
                  {restaurant_active.branches &&
                    restaurant_active.branches.length > 0 &&
                    restaurant_active.branches[0].address_full_text}
                </span>

                <span className="ml-1 whitespace-nowrap">
                  <i className="fi-rr-angle-small-right text-gray-600"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
