const Card = ({ className = '', onClick = () => {}, children }) => {
    return (
        <div className={`container mx-auto py-5 space-y-5 ${className}`} onClick={onClick}>
            {children}
        </div>
    );
};

const CardTitle = ({ children }) => {
    return children;
};

const CardBody = ({ children }) => {
    return children;
};

export { CardTitle, CardBody };
export default Card;
