import { useContext, useEffect, useRef, useState } from "react";

import Card, { CardBody, CardTitle } from "../../components/Card";
import Food, { FoodOptionModal } from "../../components/Food";

import { MenuContext } from "../../contexts/MenuContext";
import {
  API_FOOD_HEALTH_CHECK,
  API_GET_MENU_ORDER,
  ENUM_METHOD_GET,
  ENUM_PROJECT_ID_HEALTH_CHECK,
  ENUM_PROJECT_ID_JAVA_ORDER,
  FOOD_MODAL_LOCATION,
  HOME_LOCATION,
} from "../../contexts/constains";

import * as axiosTemplate from "../../utils/axiosTemplate";
import * as alertNotify from "../../utils/alertNotify";
import { formatKeysearch } from "../../utils/currency";
import images from "../../assets/images";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
import { lockScroll } from "../../utils/scrollBody";

// Before Change
// const Content = ({ searchValue }) => {
//     const { hash } = useLocation();
//     const navigate = useNavigate();
//     const {
//         menuState: { restaurant, restaurant_active, modal_brand },
//         setRestaurantActive,
//         setModalBrand,
//     } = useContext(MenuContext);

//     const {
//         cartState: { carts },
//         clearFood,
//     } = useContext(CartContext);

//     let [searchParams] = useSearchParams();

//     const [menuOrder, setMenuOrder] = useState([]);
//     const [searchValueModalBrand, setSearchValueModalBrand] = useState('');

//     let [foodOptionModalActive, setFoodOptionModalActive] = useState({ active: false, data: {} });

//     const advancedSearch = (array, searchTerm) => {
//         if (searchTerm === '') return array;
//         let str = formatKeysearch(searchTerm);
//         const results = [];
//         for (const item of array) {
//             const filteredList = item.foods.filter((subItem) => formatKeysearch(subItem.name).includes(str));
//             if (filteredList.length > 0) {
//                 results.push({ ...item, foods: filteredList });
//             }
//         }

//         return results;
//     };

//     const advancedSearchModalBrand = (array, searchTerm) => {
//         if (searchTerm === '') return array;
//         let str = formatKeysearch(searchTerm);
//         const results = array.filter((subItem) => formatKeysearch(subItem.name + subItem.address_full_text).includes(str));
//         return results;
//     };

//     const handleOpenFoodDetail = (active, food) => {
//         setFoodOptionModalActive({
//             ...foodOptionModalActive,
//             active,
//             data: food,
//         });
//         window.location.hash = FOOD_MODAL_LOCATION;
//     };

//     const handleCloseFoodDetail = () => {
//         navigate(-1);
//     };

//     const groupedByCategory = (data) => {
//         return data.reduce((acc, currentItem) => {
//             const existingCategory = acc.find((item) => item.id === currentItem.category_id);
//             if (existingCategory) {
//                 existingCategory.foods.push(currentItem);
//             } else {
//                 acc.push({
//                     id: currentItem.category_id,
//                     name: currentItem.category_name,
//                     foods: [currentItem],
//                 });
//             }
//             return acc;
//         }, []);
//     };

//     const handleSelectBrand = (data) => {
//         if (data.id === restaurant_active.id) return handleChangeRestaurantActive(restaurant_active, 2, false);
//         if (carts.length > 0)
//             alertNotify.push('Giỏ hàng của bạn đang có món ăn, nếu lựa chọn thay đổi giỏ hàng sẽ bị xoá ?', 'Đóng', 'Đồng ý', () => handleChangeRestaurantActive({ ...data, branches: [] }, 2));
//         else handleChangeRestaurantActive({ ...data, branches: [] }, 2);
//     };

//     const handleSelectBranch = (data) => {
//         if (restaurant_active.branches.length > 0 && data.id === restaurant_active.branches[0].id) return handleChangeRestaurantActive(restaurant_active, 0, false);
//         if (carts.length > 0)
//             alertNotify.push('Giỏ hàng của bạn đang có món ăn, nếu lựa chọn thay đổi giỏ hàng sẽ bị xoá ?', 'Đóng', 'Đồng ý', () =>
//                 handleChangeRestaurantActive({ ...restaurant_active, branches: [data] }, 0),
//             );
//         else handleChangeRestaurantActive({ ...restaurant_active, branches: [data] }, 0);
//     };

//     const handleCloseModalBrand = (e) => {
//         if (!e.target.closest('#modal-brand')) setModalBrand(0);
//     };

//     const handleChangeRestaurantActive = (data, type, clear = true) => {
//         setRestaurantActive(data);
//         clear && clearFood();
//         let branches = restaurant.restaurant_brands.find((br) => br.id === data.id).branches;
//         if (type === 2 && branches.length === 1 && branches[0].setting.is_enable_food_court === 0) {
//             setRestaurantActive({ ...data, branches: [branches[0]] });
//             setModalBrand(0);
//         } else setModalBrand(type);
//         setSearchValueModalBrand('');
//     };

//     useEffect(() => {
//         let qrCode = searchParams.get('qr_code');
//         if (restaurant.qr_code && (!qrCode || qrCode === '')) {
//             qrCode = restaurant.qr_code;
//         }

//         if (restaurant_active.branches && restaurant_active.branches.length > 0) {
//             const fetchGetMenu = async () => {
//                 const project = ENUM_PROJECT_ID_JAVA_ORDER;
//                 const method = ENUM_METHOD_GET;
//                 const api = API_GET_MENU_ORDER.sprintf(restaurant.id, restaurant_active.id, restaurant_active.branches[0].id, -1, 10000, 1);
//                 const res = await axiosTemplate.get(project, method, api);
//                 switch (res.status) {
//                     case 200:
//                         setMenuOrder(groupedByCategory(res.data.list));
//                         break;
//                     case 500:
//                         alertNotify.push('Lỗi dữ liệu', 'Đóng');
//                         break;
//                     default:
//                         alertNotify.push(res.message, 'Đóng');
//                         break;
//                 }
//             };

//             const fetchHealthCheck = async () => {
//                 const project = ENUM_PROJECT_ID_HEALTH_CHECK;
//                 const method = ENUM_METHOD_GET;
//                 const api = API_FOOD_HEALTH_CHECK.sprintf(restaurant.id, restaurant_active.id, restaurant_active.branches[0].id);
//                 await axiosTemplate.get(project, method, api);
//                 fetchGetMenu();
//             };

//             if (restaurant.qr_code === qrCode) fetchHealthCheck();
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [restaurant_active]);

//     useEffect(() => {
//         if (restaurant.restaurant_brands && restaurant.restaurant_brands.length > 0) {
//             let qrCode = searchParams.get('qr_code');
//             if (qrCode && (restaurant.qr_code !== qrCode || Object.keys(restaurant_active).length === 0)) {
//                 if (restaurant.restaurant_brands.length > 1) {
//                     setModalBrand(1);
//                 } else {
//                     handleSelectBrand(restaurant.restaurant_brands[0]);
//                 }
//             }
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [restaurant, restaurant_active]);

//     useEffect(() => {
//         if (hash !== `#${FOOD_MODAL_LOCATION}` && foodOptionModalActive.active) {
//             setFoodOptionModalActive({ ...foodOptionModalActive, active: false });
//         } else if (hash === `#${FOOD_MODAL_LOCATION}` && !foodOptionModalActive.active) {
//             Object.keys(foodOptionModalActive?.data).length > 0 ? setFoodOptionModalActive({ ...foodOptionModalActive, active: true }) : navigate(`/?qr_code=${restaurant.qr_code}`);
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [hash]);

//     useEffect(() => {
//         if (foodOptionModalActive.active) lockScroll(FOOD_MODAL_LOCATION);
//     }, [foodOptionModalActive.active]);

//     return (
//         <>
//             <div>
//                 {menuOrder.length > 0 && advancedSearch(menuOrder, searchValue).length > 0 ? (
//                     advancedSearch(menuOrder, searchValue).map(
//                         (menu, menuIndex) =>
//                             menu.foods.length > 0 && (
//                                 <Card key={menuIndex}>
//                                     <CardTitle>
//                                         <div>
//                                             <h2 className="text-lg font-bold">{menu.name}</h2>
//                                         </div>
//                                     </CardTitle>
//                                     <CardBody type="line">
//                                         <ul
//                                             // className={`w-full grid gap-y-5 ${
//                                             //     card.type === 'grid'
//                                             //         ? 'grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-[15px]'
//                                             //         : 'grid-cols-1 md:grid-cols-2 md:gap-x-[15px] lg:grid-cols-3'
//                                             // }`}
//                                             className="w-full grid gap-y-5 grid-cols-1 md:grid-cols-2 md:gap-x-[15px] lg:grid-cols-3"
//                                         >
//                                             {menu.foods.map((food, foodIndex) => (
//                                                 <Food key={foodIndex} data={food} type="line" onOpenFoodOption={() => handleOpenFoodDetail(true, food)} />
//                                             ))}
//                                         </ul>
//                                     </CardBody>
//                                 </Card>
//                             ),
//                     )
//                 ) : (
//                     <img src={images.bgNoFood} alt="" className="w-28 mx-auto my-8" />
//                 )}
//             </div>
//             <FoodOptionModal data={foodOptionModalActive.data} active={foodOptionModalActive.active} onClose={handleCloseFoodDetail} />
//             <div id="modal-app">
//                 <div className={`fixed inset-0 z-40 bg-gray-900-16 transition ease-out duration-700 ${modal_brand === 0 && 'invisible opacity-0 pointer-events-none'}`}></div>
//                 <div
//                     className={`fixed inset-0 z-50 flex items-center justify-center transition ease-out duration-300 delay-0 ${modal_brand === 0 && 'scale-0 opacity-0'}`}
//                     onClick={handleCloseModalBrand}
//                 >
//                     <div id="modal-brand" className="max-w-xs min-w-[320px] bg-gray-000 rounded-[10px] overflow-hidden">
//                         <div className="p-5 text-center bg-orange-brand-900 text-gray-000">
//                             <span>Vui lòng chọn {modal_brand === 1 ? 'THƯƠNG HIỆU' : 'CHI NHÁNH'}</span>
//                         </div>
//                         <div>
//                             <div className="py-2 px-4">
//                                 <div className="w-full">
//                                     <div className="relative flex px-[10px] py-[5px] bg-gray-100 rounded-full border border-gray-300">
//                                         <img src={images.searchIcon} alt="" className="absolute top-1/2 -translate-y-1/2 left-[6px]" />
//                                         <input
//                                             type="text"
//                                             value={searchValueModalBrand}
//                                             onChange={(event) => setSearchValueModalBrand(event.target.value)}
//                                             placeholder="Tìm kiếm"
//                                             className="w-full bg-gray-100 pl-5 placeholder-gray-400"
//                                         />
//                                         {searchValueModalBrand.length > 0 && (
//                                             <i onClick={() => setSearchValueModalBrand('')} className="fi-sr-cross-circle text-gray-600 absolute top-1/2 -translate-y-1/2 right-3"></i>
//                                         )}
//                                     </div>
//                                 </div>
//                             </div>
//                             <ul className="px-4 pb-4 max-h-[50vh] overflow-y-auto">
//                                 {modal_brand === 1 &&
//                                     restaurant.restaurant_brands &&
//                                     restaurant.restaurant_brands.length > 0 &&
//                                     advancedSearchModalBrand(restaurant.restaurant_brands, searchValueModalBrand).map((res, index) => (
//                                         <li key={index} className={`flex py-4 ${index !== 0 && 'border-t border-border-input'}`} onClick={() => handleSelectBrand(res)}>
//                                             <span className="mr-1 whitespace-nowrap text-blue-brand-700 translate-y-[1px]">
//                                                 <i className="fi-rr-bank icon-brand" data-toggle="tooltip" data-placement="top" data-original-title="Thương hiệu"></i>
//                                             </span>
//                                             <span className="flex-1">{res.name}</span>
//                                         </li>
//                                     ))}

//                                 {modal_brand === 2 &&
//                                     restaurant_active.id &&
//                                     advancedSearchModalBrand(restaurant.restaurant_brands.find((item) => item.id === restaurant_active.id).branches, searchValueModalBrand).map((branch, index) => {
//                                         if (branch.status === 0) return '';
//                                         if (branch.status === 1 && branch.setting.is_enable_food_court === 1) return '';
//                                         return (
//                                             <li
//                                                 key={index}
//                                                 className={`relative flex py-4 ${index !== 0 && 'border-t border-border-input'}`}
//                                                 onClick={() => branch.setting.is_enable_food_court === 0 && branch.status === 1 && handleSelectBranch(branch)}
//                                             >
//                                                 <span className="mr-1 whitespace-nowrap text-green-brand-600 translate-y-[1px]">
//                                                     <i className="fi-rr-marker icon-branch" data-toggle="tooltip" data-placement="top" data-original-title="Chi nhánh"></i>
//                                                 </span>
//                                                 <div className="flex flex-col flex-1">
//                                                     {restaurant.lat !== 0 && restaurant.lng !== 0 && (
//                                                         <div>
//                                                             <span className="mr-1 whitespace-nowrap bg-green-brand-000 text-green-600 px-2 rounded-md font-medium">
//                                                                 {Math.round(branch.distance_km * 100) / 100 + ' km'}
//                                                             </span>
//                                                         </div>
//                                                     )}
//                                                     <span className={`flex-1 ${(branch.setting.is_enable_food_court === 1 || branch.status === 0) && 'opacity-50'}`}>{branch.address_full_text}</span>
//                                                 </div>
//                                             </li>
//                                         );
//                                     })}
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// After Change
const Content = ({
  searchValue,
  menuOrder,
  setMenuOrder,
  categoryList,
  setCategoryList,
  categoryValue,
  foodOptionModalActive,
  setFoodOptionModalActive,
  setFoodNew,
  setIsLoadContent
}) => {
  const categoryRefs = useRef({});

  const { hash } = useLocation();
  const navigate = useNavigate();
  const {
    menuState: { restaurant, restaurant_active, modal_brand },
    setRestaurantActive,
    setModalBrand,
  } = useContext(MenuContext);

  const {
    cartState: { carts },
    clearFood,
  } = useContext(CartContext);

  let [searchParams] = useSearchParams();

  //   const [menuOrder, setMenuOrder] = useState([]);
  //   let [foodOptionModalActive, setFoodOptionModalActive] = useState({
  //     active: false,
  //     data: {},
  //   });

  const [searchValueModalBrand, setSearchValueModalBrand] = useState("");

  const advancedSearch = (array, searchTerm) => {
    if (searchTerm === "") return array;
    let str = formatKeysearch(searchTerm);
    const results = [];
    for (const item of array) {
      const filteredList = item.foods.filter((subItem) =>
        formatKeysearch(subItem.name).includes(str)
      );
      if (filteredList.length > 0) {
        results.push({ ...item, foods: filteredList });
      }
    }

    return results;
  };

  const advancedSearchModalBrand = (array, searchTerm) => {
    if (searchTerm === "") return array;
    let str = formatKeysearch(searchTerm);
    const results = array.filter((subItem) =>
      formatKeysearch(subItem.name + subItem.address_full_text).includes(str)
    );
    return results;
  };

  const handleOpenFoodDetail = (active, food) => {
    setFoodOptionModalActive({
      ...foodOptionModalActive,
      active,
      data: food,
    });
    window.location.hash = FOOD_MODAL_LOCATION;
  };

  const handleCloseFoodDetail = () => {
    navigate(-1);
  };

  const groupedByCategory = (data) => {
    return data.reduce((acc, currentItem) => {
      const existingCategory = acc.find(
        (item) => item.id === currentItem.category_id
      );
      if (existingCategory) {
        existingCategory.foods.push(currentItem);
      } else {
        acc.push({
          id: currentItem.category_id,
          name: currentItem.category_name,
          foods: [currentItem],
        });
      }
      return acc;
    }, []);
  };

  const getCategoryFromListFood = (data) => {
    return data.reduce(
      (uniqueCategories, item) => {
        if (
          !uniqueCategories.some((category) => category.id === item.category_id)
        ) {
          uniqueCategories.push({
            id: item.category_id,
            name: item.category_name,
            is_active: 0,
          });
        }

        return uniqueCategories;
      },
      [...categoryList]
    );
  };

  const handleSelectBrand = (data) => {
    if (data.id === restaurant_active.id)
      return handleChangeRestaurantActive(restaurant_active, 2, false);
    if (carts.length > 0)
      alertNotify.push(
        "Giỏ hàng của bạn đang có món ăn, nếu lựa chọn thay đổi giỏ hàng sẽ bị xoá ?",
        "Đóng",
        "Đồng ý",
        () => handleChangeRestaurantActive({ ...data, branches: [] }, 2)
      );
    else handleChangeRestaurantActive({ ...data, branches: [] }, 2);
  };

  const handleSelectBranch = (data) => {
    if (
      restaurant_active.branches.length > 0 &&
      data.id === restaurant_active.branches[0].id
    )
      return handleChangeRestaurantActive(restaurant_active, 0, false);
    if (carts.length > 0)
      alertNotify.push(
        "Giỏ hàng của bạn đang có món ăn, nếu lựa chọn thay đổi giỏ hàng sẽ bị xoá ?",
        "Đóng",
        "Đồng ý",
        () =>
          handleChangeRestaurantActive(
            { ...restaurant_active, branches: [data] },
            0
          )
      );
    else
      handleChangeRestaurantActive(
        { ...restaurant_active, branches: [data] },
        0
      );
  };

  const handleCloseModalBrand = (e) => {
    if (!e.target.closest("#modal-brand")) setModalBrand(0);
  };

  const handleChangeRestaurantActive = (data, type, clear = true) => {
    setRestaurantActive(data);
    clear && clearFood();
    let branches = restaurant.restaurant_brands.find(
      (br) => br.id === data.id
    ).branches;
    if (
      type === 2 &&
      branches.length === 1 &&
      branches[0].setting.is_enable_food_court === 0
    ) {
      setRestaurantActive({ ...data, branches: [branches[0]] });
      setModalBrand(0);
    } else setModalBrand(type);
    setSearchValueModalBrand("");
  };

  useEffect(() => {
    let qrCode = searchParams.get("qr_code");
    if (restaurant.qr_code && (!qrCode || qrCode === "")) {
      qrCode = restaurant.qr_code;
    }

    if (restaurant_active.branches && restaurant_active.branches.length > 0) {
      const fetchGetMenu = async () => {
        const project = ENUM_PROJECT_ID_JAVA_ORDER;
        const method = ENUM_METHOD_GET;
        const api = API_GET_MENU_ORDER.sprintf(
          restaurant.id,
          restaurant_active.id,
          restaurant_active.branches[0].id,
          -1,
          10000,
          1
        );
        const res = await axiosTemplate.get(project, method, api);
        switch (res.status) {
          case 200:
            setMenuOrder(groupedByCategory(res.data.list));
            setFoodNew(res.data.list);
            setCategoryList(getCategoryFromListFood(res.data.list));
            setIsLoadContent(true);
            break;
          case 500:
            alertNotify.push("Lỗi dữ liệu", "Đóng");
            break;
          default:
            alertNotify.push(res.message, "Đóng");
            break;
        }
      };

      const fetchHealthCheck = async () => {
        const project = ENUM_PROJECT_ID_HEALTH_CHECK;
        const method = ENUM_METHOD_GET;
        const api = API_FOOD_HEALTH_CHECK.sprintf(
          restaurant.id,
          restaurant_active.id,
          restaurant_active.branches[0].id
        );
        await axiosTemplate.get(project, method, api);
        fetchGetMenu();
      };

      if (restaurant.qr_code === qrCode) fetchHealthCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurant_active]);

  useEffect(() => {
    if (
      restaurant.restaurant_brands &&
      restaurant.restaurant_brands.length > 0
    ) {
      let qrCode = searchParams.get("qr_code");
      if (
        qrCode &&
        (restaurant.qr_code !== qrCode ||
          Object.keys(restaurant_active).length === 0)
      ) {
        if (restaurant.restaurant_brands.length > 1) {
          setModalBrand(1);
        } else {
          handleSelectBrand(restaurant.restaurant_brands[0]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurant, restaurant_active]);

  useEffect(() => {
    if (hash !== `#${FOOD_MODAL_LOCATION}` && foodOptionModalActive.active) {
      setFoodOptionModalActive({ ...foodOptionModalActive, active: false });
    } else if (
      hash === `#${FOOD_MODAL_LOCATION}` &&
      !foodOptionModalActive.active
    ) {
      Object.keys(foodOptionModalActive?.data).length > 0
        ? setFoodOptionModalActive({ ...foodOptionModalActive, active: true })
        : navigate(`/?qr_code=${restaurant.qr_code}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  useEffect(() => {
    if (foodOptionModalActive.active) lockScroll(FOOD_MODAL_LOCATION);
  }, [foodOptionModalActive.active]);

  useEffect(() => {
    if (categoryValue && categoryRefs.current[categoryValue]) {
      const element = categoryRefs.current[categoryValue];
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const headerHeightElement = 70;
      const offsetPosition = elementPosition - headerHeightElement;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [categoryValue]);

  return (
    <>
      <div className="bg-[#FFF4EE]">
        {menuOrder.length > 0 &&
        advancedSearch(menuOrder, searchValue).length > 0 ? (
          advancedSearch(menuOrder, searchValue).map(
            (menu, menuIndex) =>
              menu.foods.length > 0 && (
                <Card key={menuIndex}>
                  <CardTitle>
                    <div ref={(el) => (categoryRefs.current[menu.id] = el)}>
                      <h2 className="text-lg font-bold">{menu.name}</h2>
                    </div>
                  </CardTitle>
                  <CardBody type="line">
                    <ul className="w-full grid gap-y-5 grid-cols-1 md:grid-cols-2 md:gap-x-[15px] lg:grid-cols-3">
                      {menu.foods.map((food, foodIndex) => (
                        <Food
                          key={foodIndex}
                          data={food}
                          type="line"
                          onOpenFoodOption={() =>
                            handleOpenFoodDetail(true, food)
                          }
                        />
                      ))}
                    </ul>
                  </CardBody>
                </Card>
              )
          )
        ) : (
          <div className="bg-white">
            <img src={images.bgNoFood} alt="" className="w-28 mx-auto my-8" />
          </div>
        )}
      </div>
      <FoodOptionModal
        data={foodOptionModalActive.data}
        active={foodOptionModalActive.active}
        onClose={handleCloseFoodDetail}
      />

      <div id="modal-app">
        <div
          className={`fixed inset-0 z-40 bg-gray-900-16 transition ease-out duration-700 ${
            modal_brand === 0 && "invisible opacity-0 pointer-events-none"
          }`}
        ></div>
        <div
          className={`fixed inset-0 z-50 flex items-center justify-center transition ease-out duration-300 delay-0 ${
            modal_brand === 0 && "scale-0 opacity-0"
          }`}
          onClick={handleCloseModalBrand}
        >
          <div
            id="modal-brand"
            className="max-w-xs min-w-[320px] bg-gray-000 rounded-[10px] overflow-hidden"
          >
            <div className="p-5 text-center bg-orange-brand-900 text-gray-000">
              <span>
                Vui lòng chọn {modal_brand === 1 ? "THƯƠNG HIỆU" : "CHI NHÁNH"}
              </span>
            </div>
            <div>
              <div className="py-2 px-4">
                <div className="w-full">
                  <div className="relative flex px-[10px] py-[5px] bg-gray-100 rounded-full border border-gray-300">
                    <img
                      src={images.searchIcon}
                      alt=""
                      className="absolute top-1/2 -translate-y-1/2 left-[6px]"
                    />
                    <input
                      type="text"
                      value={searchValueModalBrand}
                      onChange={(event) =>
                        setSearchValueModalBrand(event.target.value)
                      }
                      placeholder="Tìm kiếm"
                      className="w-full bg-gray-100 pl-5 placeholder-gray-400"
                    />
                    {searchValueModalBrand.length > 0 && (
                      <i
                        onClick={() => setSearchValueModalBrand("")}
                        className="fi-sr-cross-circle text-gray-600 absolute top-1/2 -translate-y-1/2 right-3"
                      ></i>
                    )}
                  </div>
                </div>
              </div>
              <ul className="px-4 pb-4 max-h-[50vh] overflow-y-auto">
                {modal_brand === 1 &&
                  restaurant.restaurant_brands &&
                  restaurant.restaurant_brands.length > 0 &&
                  advancedSearchModalBrand(
                    restaurant.restaurant_brands,
                    searchValueModalBrand
                  ).map((res, index) => (
                    <li
                      key={index}
                      className={`flex py-4 ${
                        index !== 0 && "border-t border-border-input"
                      }`}
                      onClick={() => handleSelectBrand(res)}
                    >
                      <span className="mr-1 whitespace-nowrap text-blue-brand-700 translate-y-[1px]">
                        <i
                          className="fi-rr-bank icon-brand"
                          data-toggle="tooltip"
                          data-placement="top"
                          data-original-title="Thương hiệu"
                        ></i>
                      </span>
                      <span className="flex-1">{res.name}</span>
                    </li>
                  ))}

                {modal_brand === 2 &&
                  restaurant_active.id &&
                  advancedSearchModalBrand(
                    restaurant.restaurant_brands.find(
                      (item) => item.id === restaurant_active.id
                    ).branches,
                    searchValueModalBrand
                  ).map((branch, index) => {
                    if (branch.status === 0) return "";
                    if (
                      branch.status === 1 &&
                      branch.setting.is_enable_food_court === 1
                    )
                      return "";
                    return (
                      <li
                        key={index}
                        className={`relative flex py-4 ${
                          index !== 0 && "border-t border-border-input"
                        }`}
                        onClick={() =>
                          branch.setting.is_enable_food_court === 0 &&
                          branch.status === 1 &&
                          handleSelectBranch(branch)
                        }
                      >
                        <span className="mr-1 whitespace-nowrap text-green-brand-600 translate-y-[1px]">
                          <i
                            className="fi-rr-marker icon-branch"
                            data-toggle="tooltip"
                            data-placement="top"
                            data-original-title="Chi nhánh"
                          ></i>
                        </span>
                        <div className="flex flex-col flex-1">
                          {restaurant.lat !== 0 && restaurant.lng !== 0 && (
                            <div>
                              <span className="mr-1 whitespace-nowrap bg-green-brand-000 text-green-600 px-2 rounded-md font-medium">
                                {Math.round(branch.distance_km * 100) / 100 +
                                  " km"}
                              </span>
                            </div>
                          )}
                          <span
                            className={`flex-1 ${
                              (branch.setting.is_enable_food_court === 1 ||
                                branch.status === 0) &&
                              "opacity-50"
                            }`}
                          >
                            {branch.address_full_text}
                          </span>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
