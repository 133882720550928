import { createContext, useReducer } from 'react';

import { cartReducer } from '../reducers/CartReducer';

import { ADD_FOOD, CART_STORAGE_KEY, CLEAR_FOOD, EDIT_FOOD, INFO_CUSTOMER_STORAGE_KEY, REMOVE_FOOD, RESET_CUSTOMER, TOTAL_CART_STORAGE_KEY, UPDATE_CUSTOMER } from './constains';
import storage from '../utils/storage';

export const CartContext = createContext();

const CartProvider = ({ children }) => {
    const [cartState, dispatch] = useReducer(cartReducer, {
        carts: storage.get(CART_STORAGE_KEY, []),
        total: storage.get(TOTAL_CART_STORAGE_KEY, 0),
        infoCustomer: storage.get(INFO_CUSTOMER_STORAGE_KEY, {
            name: '',
            phone: '',
            address: {
                address: '',
                note: '',
            },
            isUpdate: 0,
        }),
    });

    const getFood = async () => {
        return cartState;
    };

    // Add food
    const addFood = async (newFood) => {
        dispatch({ type: ADD_FOOD, payload: newFood });
        return true;
    };

    // Add food
    const editFood = async (food) => {
        dispatch({ type: EDIT_FOOD, payload: food });
        return true;
    };

    // Remove food
    const removeFood = async (id) => {
        dispatch({ type: REMOVE_FOOD, payload: id });
        return true;
    };

    // Clear food
    const clearFood = async (id) => {
        dispatch({ type: CLEAR_FOOD });
        return true;
    };

    // Update info customer
    const updateCustomer = async (data) => {
        dispatch({ type: UPDATE_CUSTOMER, payload: data });
        return true;
    };

    // Update info customer
    const resetCustomer = async (data) => {
        dispatch({ type: RESET_CUSTOMER, payload: data });
        return true;
    };

    const cartContextData = {
        cartState,
        addFood,
        getFood,
        removeFood,
        clearFood,
        editFood,
        updateCustomer,
        resetCustomer,
    };

    return <CartContext.Provider value={cartContextData}>{children}</CartContext.Provider>;
};

export default CartProvider;
